/* eslint-disable react-hooks/exhaustive-deps */
import * as yup from 'yup';
import Papa from 'papaparse';
import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { invoiceActions } from './reducer';
import { withRouter } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import { yupResolver } from '@hookform/resolvers/yup';
import { actionProps, selectState } from '../../../utils/reduxActions';
import { transactionListActions } from '../../../views/TransactionHistory/reducer';
import {
  FACTURAMA_GENERAL_PUBLIC_RFC,
  FACTURAMA_GENERAL_PUBLIC_RFC_NAME,
  FACTURAMA_GENERALPUBLIC_CFDI,
  FACTURAMA_GENERAL_PUBLIC_CODIGO_POSTAL,
  FACTURAMA_GENERAL_PUBLIC_REGIMEN_FISCAL,
} from '../../../utils/constants';

interface Cfdi {
  Natural: boolean;
  Moral: boolean;
  Name: string;
  Value: string;
}

interface PaymentForm {
  Name: string;
  Value: string;
}

interface CreateinvoiceFormInputs {
  paymentForm: string;
  receiver: {
    rfc: string;
    name: string;
    cfdiUse: string;
    fiscalRegime: string;
    zipCode: string;
  };
  transactionIds: string;
  emails?: string;
  sendToAnotherEmail?: boolean;
  generalPublic?: boolean;
  amount?: number;
  paymentMethod?: string;
  notes?: string;
  includeBox?: boolean;
  boxAmount?: number;
}

const CreateinvoiceSchema = yup.object().shape({
  paymentForm: yup.string().trim().required('Campo requerido'),
  receiver: yup.object().shape({
    rfc: yup.string().trim().required('Campo requerido'),
    name: yup.string().trim().required('Campo requerido'),
    cfdiUse: yup.string().trim().required('Campo requerido'),
    fiscalRegime: yup.string().trim().required('Campo requerido'),
    zipCode: yup.string().trim().required('Campo requerido'),
  }),
  transactionIds: yup.string().trim().required('Campo requerido'),
  emails: yup
    .string()
    .optional()
    .matches(/^([a-z][a-z0-9_.]+@([a-z0-9-]+\.)+[a-z]{2,6}(, )*)+$/),
  sendToAnotherEmail: yup.boolean().optional(),
  generalPublic: yup.boolean().optional(),
  amount: yup.number().optional(),
  paymentMethod: yup.string().required('Campo requerido'),
  notes: yup.string().optional().default(''),
  includeBox: yup.boolean().optional().default(false),
  boxAmount: yup.number().when('includeBox', {
    is: true,
    then: yup.number().required('Campo requerido'),
    otherwise: yup.number().optional(),
  }),
});

interface InvoiceProps {
  actions?: any;
  invoiceControls?: any;
  history?: any;
  listOfCfdi?: Cfdi[];
  listOfRegimeFiscal?: Cfdi[];
  paymentForms?: PaymentForm[];
  profile?: any;
}

const Invoice: React.FC<InvoiceProps> = props => {
  const [csvData, setCSVData] = useState<any[]>([]);
  const [fileName, setFileName] = useState<string | null>(null);
  const {
    actions,
    invoiceControls,
    listOfCfdi,
    listOfRegimeFiscal,
    paymentForms,
    profile
  } = props;
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm<CreateinvoiceFormInputs>({
    resolver: yupResolver(CreateinvoiceSchema),
  });
  const sendToAnotherEmail = watch('sendToAnotherEmail');
  const generalPublic = watch('generalPublic');
  const includeBox = watch('includeBox');

  useEffect(() => {
    actions.getListOfCfdi();
    actions.getListOfRegimeFiscal();
    actions.getPaymentForms();
  }, [actions]);

  useEffect(() => {
    if (generalPublic) {
      setValue('receiver.rfc', FACTURAMA_GENERAL_PUBLIC_RFC);
      setValue('receiver.name', FACTURAMA_GENERAL_PUBLIC_RFC_NAME);
      setValue('receiver.cfdiUse', FACTURAMA_GENERALPUBLIC_CFDI);
      setValue('receiver.zipCode', FACTURAMA_GENERAL_PUBLIC_CODIGO_POSTAL);
      setValue(
        'receiver.fiscalRegime',
        FACTURAMA_GENERAL_PUBLIC_REGIMEN_FISCAL,
      );
    } else {
      setValue('receiver.rfc', '');
      setValue('receiver.name', '');
      setValue('receiver.cfdiUse', '');
      setValue('receiver.zipCode', '');
      setValue('receiver.fiscalRegime', '');
    }
  }, [generalPublic, setValue]);

  const onSubmit = (data: CreateinvoiceFormInputs) => {
    if (invoiceControls?.transactionIds?.length)
      return actions.createInvoice({ ...data });
    else return actions.createNewInvoice({ ...data, csvData });
  };
  const allAreInabit = invoiceControls?.serviceName.every(
    (e: string) => e === 'INABIT',
  );

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = e.target.files?.[0];
    if (selectedFile) {
      setFileName(selectedFile.name);
      Papa.parse(selectedFile, {
        header: true,
        skipEmptyLines: 'greedy', // Skip empty lines
        complete: (result: any) => {
          const data = result.data as any[];
          setCSVData(data);
        },
        error: (error: any) => {
          console.error('Error parsing CSV:', error);
        },
      });
    } else {
      setFileName(null);
    }
  };

  return (
    <>
      <Dimmer
        active={invoiceControls.loading}
        inverted
      >
        <Loader inverted>Generando factura.....</Loader>
      </Dimmer>
      <div className='m-auto text-center'>
        <h2 className='text-2xl font-bold my-4 '>
          <span className='uppercase'>Crear factura</span>{' '}
          {invoiceControls.guides.length
            ? `Guia No.(${invoiceControls.guides.join(',')})`
            : ''}
        </h2>
        <hr className='mt-1 mb-4 md:min-w-full ' />
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className='grid w-full grid-cols-1 md:grid-cols-2 md:space-x-3'>
          <div className='grid'>
            <div className='grid'>
              <span className='font-bold text-xl uppercase'>
                Datos de transacción
              </span>
              <hr className='mt-1 mb-4 md:min-w-full' />
            </div>
            <div className='grid'>
              <label>ID. de transacción</label>
              <input
                className={`w-full border-2 py-2 px-4 mt-2 text-xl rounded-lg focus:outline-none ${
                  errors.transactionIds ? 'border-red-500' : ''
                }`}
                placeholder='RFC'
                {...register('transactionIds')}
                value={
                  invoiceControls?.transactionIds?.length
                    ? invoiceControls?.transactionIds?.join(',')
                    : 'Servicios de Cadena de Suministro'
                }
              />
              <span className='text-red-600 font-semibold h-4'>
                {errors.transactionIds?.message}
              </span>
            </div>
            <div className='grid'>
              <label>Forma de pago</label>
              <select
                {...register('paymentForm')}
                className={`w-full border-2 py-2 px-4 mt-2 text-xl rounded-lg focus:outline-none  ${
                  errors.paymentForm ? 'border-red-500' : ''
                }`}
              >
                <option
                  key={''}
                  value={''}
                  disabled
                  selected={invoiceControls.paymentForm === '' ? true : false}
                ></option>
                {paymentForms?.map(form => (
                  <option
                    key={form.Value}
                    value={form.Value}
                    selected={
                      invoiceControls.paymentForm === form.Value ? true : false
                    }
                  >
                    {form.Value} - {form.Name}
                  </option>
                ))}
              </select>
              <span className='text-red-600 font-semibold h-4'>
                {errors.paymentForm?.message}
              </span>
            </div>
          </div>
          <div className='grid'>
            <span className='font-bold text-xl uppercase'>Datos fiscales</span>
            <hr className='mt-1 mb-4 md:min-w-full' />
            <div className='grid'>
              <label>Regimen fiscal</label>
              <select
                {...register('receiver.fiscalRegime')}
                className={`w-full border-2 py-2 px-4 mt-2 text-xl rounded-lg focus:outline-none  ${
                  errors.receiver?.fiscalRegime ? 'border-red-500' : ''
                }`}
              >
                <option
                  key={''}
                  value={''}
                  disabled
                  selected
                ></option>
                {listOfRegimeFiscal?.map(regimeFiscal => (
                  <option
                    key={regimeFiscal.Value}
                    value={regimeFiscal.Value}
                  >
                    {regimeFiscal.Value} - {regimeFiscal.Name}
                  </option>
                ))}
              </select>
              <span className='text-red-600 font-semibold h-4'>
                {errors.receiver?.fiscalRegime?.message}
              </span>
            </div>
            <div className='grid'>
              <label>Seleccionar CFDI</label>
              <select
                {...register('receiver.cfdiUse')}
                className={`w-full border-2 py-2 px-4 mt-2 text-xl rounded-lg focus:outline-none  ${
                  errors.receiver?.cfdiUse ? 'border-red-500' : ''
                }`}
              >
                <option
                  key={''}
                  value={''}
                  disabled
                  selected
                ></option>
                {listOfCfdi?.map(cfdi => (
                  <option
                    key={cfdi.Value}
                    value={cfdi.Value}
                  >
                    {cfdi.Value} - {cfdi.Name}
                  </option>
                ))}
              </select>
              <span className='text-red-600 font-semibold h-4'>
                {errors.receiver?.cfdiUse?.message}
              </span>
            </div>
          </div>
        </div>
        <div className='grid w-full grid-cols-1 md:grid-cols-2 md:space-x-3'>
          <div className='grid'>
            <label>Método de Pago</label>
            <select
              {...register('paymentMethod')}
              className={`w-full border-2 py-2 px-4 mt-2 text-xl rounded-lg focus:outline-none  ${
                errors.paymentForm ? 'border-red-500' : ''
              }`}
            >
              <option
                key='PUE'
                value='PUE'
              >
                Pago en una sola exhibición
              </option>
              <option
                key='PPD'
                value='PPD'
              >
                Pago en parcialidades o diferido
              </option>
            </select>
            <span className='text-red-600 font-semibold h-4'>
              {errors.paymentMethod?.message}
            </span>
          </div>
          {allAreInabit && (
            <div className='grid'>
              <label>Monto</label>
              <input
                className={`w-full border-2 py-2 px-4 mt-2 text-xl rounded-lg focus:outline-none ${
                  errors.amount ? 'border-red-500' : ''
                }`}
                placeholder='Cantidad a facturar x transacción'
                {...register('amount')}
                value={invoiceControls?.amount}
                type='number'
                step='0.01'
              />
              <span className='text-red-600 font-semibold h-4'>
                {errors.amount?.message}
              </span>
            </div>
          )}
        </div>
        <div className='m-auto my-4'>
          <div className='flex items-center mb-4'>
            <input
              type='checkbox'
              className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
              {...register('generalPublic')}
            />
            <label className='ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 '>
              Público en General
            </label>
          </div>
          <span className='font-bold text-xl uppercase mt-4'>
            {generalPublic
              ? 'Datos Público en General'
              : 'Datos de empresa receptora'}
          </span>
          <hr className='mt-1 mb-4 md:min-w-full' />
        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 md:space-x-3'>
          <div className='grid'>
            <label>RFC</label>
            <input
              className={`w-full border-2 py-2 px-4 mt-2 text-xl rounded-lg focus:outline-none ${
                errors.receiver?.rfc ? 'border-red-500' : ''
              }`}
              placeholder='RFC'
              {...register('receiver.rfc')}
            />
            <span className='text-red-600 font-semibold h-4'>
              {errors.receiver?.rfc?.message}
            </span>
          </div>
          <div className='grid'>
            <label>Razón social sin régimen societario</label>
            <input
              className={`w-full border-2 py-2 px-4 mt-2 text-xl rounded-lg focus:outline-none ${
                errors.receiver?.name ? 'border-red-500' : ''
              }`}
              placeholder='Razón social sin régimen societario'
              {...register('receiver.name')}
            />
            <span className='text-red-600 font-semibold h-4'>
              {errors.receiver?.name?.message}
            </span>
          </div>
        </div>
        <div className='grid grid-cols-1'>
          <div className='grid'>
            <span className='font-bold text-xl uppercase'>
              Datos de dirección
            </span>
            <hr className='mt-1 mb-4 md:min-w-full' />
          </div>
          <div className='grid'>
            <label>Código postal</label>
            <input
              className={`w-full border-2 py-2 px-4 mt-2 text-xl rounded-lg focus:outline-none ${
                errors.receiver?.zipCode ? 'border-red-500' : ''
              }`}
              placeholder='Código postal registrado en el SAT'
              {...register('receiver.zipCode')}
            />
            <span className='text-red-600 font-semibold h-4'>
              {errors.receiver?.zipCode?.message}
            </span>
          </div>
        </div>
        {/* new field */}
        <div className='grid grid-cols-1'>
          <div className='grid'>
            <hr className='mt-1 mb-4 md:min-w-full' />
          </div>
          <div className='flex items-center mb-4'>
            <input
              type='checkbox'
              className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
              {...register('includeBox')}
            />
            <label className='ml-2 text-sm font-medium text-gray-900 dark:text-gray-300 '>
              Agregar insumos para empaque
            </label>
          </div>
        </div>
        {includeBox && (
          <div className='grid'>
            <label>Monto por concepto de insumos</label>
            <input
              className={`w-full border-2 py-2 px-4 mt-2 text-xl rounded-lg focus:outline-none ${
                errors.boxAmount ? 'border-red-500' : ''
              }`}
              placeholder='Monto por concepto de insumos'
              {...register('boxAmount')}
              value={invoiceControls?.boxAmount}
              type='number'
            />
            <span className='text-red-600 font-semibold h-4'>
              {errors.boxAmount?.message}
            </span>
          </div>
        )}
        {/* end of new  */}
        <div className='grid grid-cols-1'>
          <div className='grid'>
            <hr className='mt-1 mb-4 md:min-w-full' />
          </div>
          <div className='grid'>
            <label>Notas (Opcional)</label>
            <input
              type='text'
              className={`w-full border-2 py-2 px-4 mt-2 text-xl rounded-lg focus:outline-none ${
                errors.notes ? 'border-red-500' : ''
              }`}
              {...register('notes')}
            />
            <span className='text-red-600 font-semibold h-4'>
              {errors.notes?.message}
            </span>
          </div>
        </div>
        <div className='grid grid-cols-1'>
          <div className='grid'>
            <div className='flex py-6 flex-col mb-2'>
              <div className='mb-1'>
                <input
                  className='h-6 w-6 mr-2 border border-gray-300 rounded-sm bg-white'
                  type='checkbox'
                  {...register('sendToAnotherEmail')}
                />
                <label className='inline-block text-gray-800 text-center text-lg'>
                  Enviar factura a otros correos electrónicos
                </label>
              </div>
            </div>
          </div>
          {sendToAnotherEmail && (
            <div className='grid'>
              <label>Correo electrónicos</label>
              <input
                className={`w-full border-2 py-2 px-4 mt-2 text-xl rounded-lg focus:outline-none ${
                  errors.emails ? 'border-red-500' : ''
                }`}
                placeholder='Correos electronicos separados por comas'
                {...register('emails')}
              />
              <span className='text-gray-400'>
                {' '}
                (user1@gmail.com, user2@gmail.com)
              </span>
              <span className='text-red-600 font-semibold h-4'>
                {errors.emails?.message}
              </span>
            </div>
          )}
        </div>
        {profile.canInvoiceFromCsv && !invoiceControls?.transactionIds?.length && (
          <div className='border-2 border-dashed border-gray-400 p-6 w-full text-center cursor-pointer relative hover:border-solid'>
            <input
              type='file'
              id='fileInput'
              accept='.csv'
              className='hidden'
              onChange={handleFileChange}
            />
            <label
              htmlFor='fileInput'
              id='fileLabel'
              className='block text-gray-700 cursor-pointer'
            >
              {fileName ?? 'Da click o arrastra un .csv aquí'}
            </label>
          </div>
        )}
        <div className='grid w-full grid-cols-1 m-auto my-6'>
          <input
            type='submit'
            value='Generar factura'
            className={`bg-primary-inabit-red py-4 text-2xl text-white rounded-xl ${
              Object.keys(errors).length > 0
                ? 'opacity-50 cursor-not-allowed'
                : 'hover:bg-primary-inabit-coffe'
            }`}
          ></input>
        </div>
      </form>
    </>
  );
};

const withConnect = connect(
  selectState(
    'invoice.listOfCfdi',
    'invoice.listOfRegimeFiscal',
    'invoice.paymentForms',
    'invoice.invoiceControls',
    'app.profile',
  ),
  actionProps({ ...invoiceActions, ...transactionListActions }),
);

export default withRouter(withConnect(Invoice));
