import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actionProps, selectState } from '../../utils/reduxActions';
import { generateShipmentActions } from './reducer';
import { bookAddressActions } from '../Address/AddressBook/reducer';
import { createAddressActions } from '../Address/CreateAddress/reducer';
import '../styles/views.css';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { PkgDetail } from '../../interfaces/general';
import SearchInput from '../../commons/Components/SearchInput'
import { Dimmer, Loader } from 'semantic-ui-react';
import axios from 'axios';
import { getItemInStorage } from '../../utils/functions';
import { SERVER_URL, APPLICATION_KEY, SECRET_KEY } from '../../utils/constants'
import QuoteResume from '../../commons/Components/QuoteResume';

interface PkgFullDetail extends PkgDetail {
  productIdSAT: string
  declaredValue: number
  type: string
}

interface GenerateGuideForm {
  shipmentPktDetails?: PkgFullDetail[]
  shipmentEnvDetails?: PkgFullDetail | null
}

interface AddressDetailProps {
  actions?: any
  shipmentControls?: any
  history?: any
  calculatorForm?: any
  productCatalog?: any
  profile?: any
}

const packageTypes = [
  // { key: 'PAQUETE', text: 'PAQUETE', value: 'PAQUETE' },
  { key: 'PACKETS', text: 'CAJA', value: 'PACKETS' },
  { key: 'BOLSA', text: 'BOLSA', value: 'BOLSA' },
  { key: 'PIEZA', text: 'PIEZA', value: 'PIEZA' },
  { key: 'TARIMA', text: 'TARIMA', value: 'TARIMA' },
  { key: 'PALETT', text: 'PALETT', value: 'PALETT' },
  { key: 'ATADO', text: 'ATADO A', value: 'ATADO' },
  { key: 'BULTO', text: 'BULTO', value: 'BULTO' },
  { key: 'CUBETA', text: 'CUBETA', value: 'CUBETA' },
  { key: 'HUACAL', text: 'HUACAL', value: 'HUACAL' },
]

const schema = yup.object({
  shipmentPktDetails: yup.array().of(yup.object({
    quantity: yup.number().positive().integer().required().min(1).max(20),
    weight: yup.number().positive().required().min(1),
    longShip: yup.number().positive().required().min(1),
    widthShip: yup.number().positive().required().min(1),
    highShip: yup.number().positive().required().min(1),
    productIdSAT: yup.string().required(),
    declaredValue: yup.number().positive().required(),
    type: yup.string().required()
  })).notRequired(),
  shipmentEnvDetails: yup.object({ // details to envelope 
    quantity: yup.number().positive().integer().min(1).max(20),
    weight: yup.number().positive().min(1).max(1),
    longShip: yup.number().positive().min(2),
    widthShip: yup.number().positive().min(2),
    highShip: yup.number().positive().min(1.5).max(1.5),
    productIdSAT: yup.string().required(),
    declaredValue: yup.number().positive().required(),
    type: yup.string().required(),
  }).nullable()
}).required();

const ProductDetailView: React.FC<AddressDetailProps> = (props) => {
  const { actions, shipmentControls, history, calculatorForm, productCatalog, profile } = props;
  const { register, handleSubmit, formState: { errors }, setValue, control } = useForm<GenerateGuideForm>({
    resolver: yupResolver(schema)
  });
  const token = getItemInStorage('token');

  useEffect(() => {
    if (calculatorForm.amountOfEnvelope > 0) {
      setValue('shipmentEnvDetails', {
        ...calculatorForm.shipmentEnvDetails,
        productIdSAT: "14111532",
        declaredValue: calculatorForm.shipmentEnvDetails.quantity * 1,
        type: "ENVELOP",
        description: 'DOCUMENTOS'
      })
    } else {
      setValue('shipmentEnvDetails', null)
    }
    if (calculatorForm.amountOfPackets) {
      setValue('shipmentPktDetails', calculatorForm.shipmentPktDetails)
    } else {
      setValue('shipmentPktDetails', [])
    }
  }, [calculatorForm, setValue]);

  const prevStep = () => actions.shipmentcontrolsChanged({ step: 1 })

  const createOrder = (data: any) => {
    const addressesToSave: any[] = [];
    if (shipmentControls.saveOriginAddress)
      addressesToSave.push({
        addressType: 'ORIGIN',
        nameOfIndividual: shipmentControls.origin.clientName,
        email: shipmentControls.origin.email,
        phone: shipmentControls.origin.phoneNumber,
        companyName: shipmentControls.origin.companyName,
        address: {
          address: shipmentControls.origin.streetName,
          interiorNumber: shipmentControls.origin.streetNumber,
          zipCode: shipmentControls.origin.postalCode,
          colonyName: shipmentControls.origin.colonyName,
          city: shipmentControls.origin.city,
          state: shipmentControls.origin.state,
          country: 'México',
        }
      })
    if (shipmentControls.saveDestAddress)
      addressesToSave.push({
        addressType: 'DESTINY',
        nameOfIndividual: shipmentControls.destination.clientName,
        email: shipmentControls.destination.email,
        phone: shipmentControls.destination.phoneNumber,
        companyName: shipmentControls.destination.companyName,
        address: {
          address: shipmentControls.destination.streetName,
          interiorNumber: shipmentControls.destination.streetNumber,
          zipCode: shipmentControls.destination.postalCode,
          colonyName: shipmentControls.destination.colonyName,
          city: shipmentControls.destination.city,
          state: shipmentControls.destination.state,
          country: 'México',
        }
      })
    
    actions.generateShipment({
      pktDetails: data,
      pickupOrder: calculatorForm.pickupOrder,
      acknowledgmentXT: calculatorForm.acknowledgmentXT,
      pickupOrderDate: calculatorForm.pickupDate,
      carrierSelected: calculatorForm.shipmentSelect,
      ocurreService: calculatorForm.ocurreService,
      originData: shipmentControls.originZipCode || calculatorForm.origData,
      destData: shipmentControls.destZipCode || calculatorForm.destData,
      ...shipmentControls,
      history,
      shopifyOrder: calculatorForm.shopifyOrder?.order?.id,
      wooCommerceOrder: calculatorForm.wooCommerceOrder?.order?.id
    });
    if (addressesToSave.length > 0)
      axios.post(`${SERVER_URL}/addresses-book/batch`, addressesToSave, {
        headers: {
          Authorization: `Bearer ${token}`,
          'application': APPLICATION_KEY,
          'secret': SECRET_KEY,
          'Content-Type': 'application/json',
        },
      })
  }

  return (
    <>
      <form onSubmit={handleSubmit(createOrder)} className="py-4 rounded-lg px-2">
        <Dimmer active={shipmentControls.loading} inverted>
          <Loader inverted>Generando orden de envio..</Loader>
        </Dimmer>
        <QuoteResume calculatorForm={calculatorForm} profile={profile} />
        <h3 className='text-2xl font-bold mb-4'>Información complementaria de paquetes:</h3>
        <hr className="mt-1 mb-4 md:min-w-full " />
        <div className='shadow-lg rounded-lg'>
          <table className="table w-full text-lg rounded-lg shadow-lg p-4">
            {
              calculatorForm.amountOfEnvelope > 0 ?
                <>
                  <thead className='bg-gray-300 rounded-t-lg'>
                    <tr className='border-slate-600 rounded-t-lg'>
                      <th colSpan={6} className='font-4xl py-4 text-center border-b border-gray-200 uppercase'>Sobres</th>
                    </tr>
                    <tr>
                      <th className='font-medium py-4 text-center'>Cantidad</th>
                      <th className='font-medium py-4 text-center'>Peso (KG)</th>
                      <th className='font-medium py-4 text-center'>Medidas (L x An x Al CM)</th>
                      <th className='font-medium py-4 text-center'>Tipo</th>
                      <th className='font-medium py-4 text-center'>Valor declarado</th>
                      <th className='font-medium py-4 text-center'>Descripción</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    <tr>
                      <td className='border-b border-slate-100 py-4 text-center'>{calculatorForm.shipmentEnvDetails.quantity}</td>
                      <td className='border-b border-slate-100 py-4 text-center'>{calculatorForm.shipmentEnvDetails.weight} KG</td>
                      <td className='border-b border-slate-100 py-4 text-center'>
                        {`${calculatorForm.shipmentEnvDetails.longShip} x ${calculatorForm.shipmentEnvDetails.widthShip} x ${calculatorForm.shipmentEnvDetails.highShip} CM`}
                      </td>
                      <td className='border-b border-slate-100 py-4 text-center'>
                        <select
                          {...register('shipmentEnvDetails.type')}
                          defaultValue="ENVELOP"
                          className={`w-full border-b-2 p-2 focus:outline-none`}>
                          <option key={"SOBRE"} value={"ENVELOP"}>SOBRE</option>
                        </select>
                      </td>
                      <td className='border-b border-slate-100 py-4 text-center'>{`${calculatorForm.shipmentEnvDetails.quantity * 1} MXN`}</td>
                      <td className='border-b border-slate-100 py-4 text-center'>
                        <span>Papel kits de papeles surtidos - 14111532</span>
                      </td>
                    </tr>
                  </tbody>
                </>
                :
                <></>
            }
            {
              calculatorForm.amountOfPackets > 0 ?
                <>
                  <thead className='bg-gray-300'>
                    <tr>
                      <th colSpan={6} className='font-4xl py-4  text-center border-b border-gray-200 uppercase'>Paquetes</th>
                    </tr>
                    <tr>
                      <th className='font-medium py-4 text-center'>Cantidad</th>
                      <th className='font-medium py-4 text-center'>Peso (KG)</th>
                      <th className='font-medium py-4 text-center'>Medidas (L x An x Al CM)</th>
                      <th className='font-medium py-4 text-center'>Tipo</th>
                      <th className='font-medium py-4 text-center'>Valor declarado</th>
                      <th className='font-medium py-4 text-center'>Descripción</th>
                    </tr>
                  </thead>
                  <tbody className="bg-white rounded-b-lg">
                    {
                      calculatorForm.shipmentPktDetails?.map((item: PkgFullDetail, i: number): JSX.Element => (
                        <tr key={i}>
                          <td className='border-b border-slate-100 py-4 text-center'>{item.quantity}</td>
                          <td className='border-b border-slate-100 py-4 text-center'>{item.weight} KG</td>
                          <td className='border-b border-slate-100 py-4 text-center'>
                            {`${item.longShip}  x ${item.widthShip} x ${item.highShip} CM`}
                          </td>
                          <td className='border-b border-slate-100 py-4 text-center'>
                            <select
                              {...register(`shipmentPktDetails.${i}.type`)}
                              className={`w-full border-b-2 p-2 focus:outline-none ${errors.shipmentPktDetails?.[i]?.type ? 'border-red-500' : ''}`}
                            >
                              <option key={""} value={""}></option>
                              {packageTypes.map((value: any) => (
                                <option key={value.key} value={value.value}>
                                  {value.text}
                                </option>
                              ))}
                            </select>
                          </td>
                          <td className='border-b border-slate-100 py-4 text-center'>
                            <input
                              {...register(`shipmentPktDetails.${i}.declaredValue`, { required: true })}
                              className={`px-4 py-1 border-b-2 focus:outline-none ${errors.shipmentPktDetails?.[i]?.declaredValue ? 'border-red-500' : ''}`}
                              type='number'
                              placeholder='Valor declarado'
                            />
                          </td>
                          <td className='border-b border-slate-100 p-4 text-center'>
                            <Controller
                              name={`shipmentPktDetails.${i}.productIdSAT`}
                              control={control}
                              rules={{
                                required: {
                                  value: true,
                                  message: 'Email is required',
                                },
                              }}
                              render={({
                                field: {
                                  name, onBlur, value, onChange
                                },
                              }) => (
                                <SearchInput
                                  name={name}
                                  options={productCatalog}
                                  filterText={value}
                                  error={`errors.shipmentPktDetails.${i}.productIdSAT`}
                                  onChange={(event) => {
                                    onChange(event.target.value)
                                    actions.getProductCatalog(event.target.value)
                                  }}
                                  value={value}
                                  onBlur={onBlur}
                                  onSelected={(e: any) => setValue(`shipmentPktDetails.${i}.productIdSAT`, e)}
                                />
                              )}
                            />
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </>
                :
                <></>
            }
          </table>
        </div>
        <div className="grid grid-cols-2 space-x-4 my-2">
          <div className='grid justify-items-start'>
            <button
              className='bg-primary-inabit-red text-white font-bold py-4 px-4 rounded-full w-64 mt-8 hover:bg-primary-inabit-coffe'
              onClick={() => prevStep()}
            >
              Volver
            </button>
          </div>
          <div className='grid justify-items-end'>
            <input
              type="submit"
              value="Generar guía"
              className={`bg-primary-inabit-red text-white font-bold py-4 px-4 rounded-full w-64 mt-8 bg-primary-inabit-red text-white font-bold py-4 px-4 rounded-full w-64 mt-8 ${Object.keys(errors).length >= 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-primary-inabit-coffe'}`}
            />
          </div>
        </div>
      </form>
    </>
  )
}

const withConnect = connect(
  selectState(
    'generateShipment.shipmentControls',
    'generateShipment.result',
    'calculator.calculatorForm',
    'generateShipment.productCatalog',
    'app.profile'
  ),
  actionProps({ ...generateShipmentActions, ...bookAddressActions, ...createAddressActions }),
);

export default withRouter(withConnect(ProductDetailView));
