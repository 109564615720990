import Swal from 'sweetalert2'
import { get, post } from '../../utils/api';
import { appActions } from '../../commons/reducer';
import { shipmentActions, SHIPMENT } from './reducer';
import { takeLatest, spawn, put } from 'redux-saga/effects';

function* shipmentList() {
  yield takeLatest(SHIPMENT.GET_SHIPMENT_LIST, function* fn(action: any): any {
    try {
      const response = yield get('/shipping');
      if (response.error) {
        yield put(shipmentActions.getShipmentListFailed({ response: response.errorMsg }));
      } else {
        yield put(shipmentActions.getShipmentListSuccess(response));
      }
    } catch {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  }
  );
}

function* downloadReport() {
  yield takeLatest(SHIPMENT.GET_REPORT, function* fn(action: any): any {
    try {
      const response = yield post('/shipping/report', { ...action.report.filters });
      if (response.error) {
        const error = JSON.parse(response.errorMsg);
        if (error.error.name === 'InputError') {
          Swal.fire({
            icon: 'error',
            title: `Ooops, ocurrio un error`,
            html: error.error.details.map((e: any) => `<p>${e.context.value}, ${e.message}</p>`),
          })
        }else{
          Swal.fire({
            icon: 'error',
            title: `Ooops, tenemos problemas para generar el reporte`,
            text: JSON.parse(response.errorMsg).error.message,
          })
        }
        yield put(shipmentActions.getReportFailed({ response: response.errorMsg }));
      } else {
        yield put(shipmentActions.getReportSuccess(response.data));
      }
    } catch {
      yield put(appActions.serverFailed('Oops, server failed'));
    }
  }
  );
}

export default function* saga(): any {
  yield spawn(shipmentList);
  yield spawn(downloadReport);
}
