import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actionProps, selectState } from '../../utils/reduxActions';
import { calculatorActions } from './reducer';
import paths from '../../config/paths'
import {
  Image,
  Dimmer, Loader
} from 'semantic-ui-react';
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { bookAddressActions } from '../Address/AddressBook/reducer';
import { getColonies, existPostalCode } from '../../utils/colonies'

//images carriers
import fedexImg from '../../assets/img/fedex-inabit.svg';
import expressImg from '../../assets/img/express-inabit.svg';
import dhlImg from '../../assets/img/dhl-inabit.svg';
import upsImg from '../../assets/img/ups-inabit.svg';
import inabitImg from '../../assets/img/inabit-service.svg';
import DeliveryTruck from '../../assets/img/delivery-truck.svg';
import CalculatoSvg from '../../assets/img/calculator.svg'
import { PkgDetail } from '../../interfaces/general';
import TextField from '@mui/material/TextField';

interface CalculatorFormInput {
  originSelected: string;
  destinationSelected: string;
  clientAddrOrig: {
    zipCode: string
    colonyName: string
  },
  clientAddrDest: {
    zipCode: string
    colonyName: string
  }
  amountOfEnvelope: string
  amountOfPackets: string
  shipmentPktDetails: PkgDetail[]
  shipmentEnvDetails: PkgDetail | null
  pickupOrder: boolean,
  acknowledgmentXT: string;
  pickupDate: string,
  ocurreService: boolean,
}

const schema = yup.object({
  originSelected: yup.string(),
  destinationSelected: yup.string(),
  clientAddrOrig: yup.object({
    zipCode: yup.string()
      .min(5, 'El código postal debe constar de  min. 5 números')
      .max(5, 'El código postal debe constar de  max. 5 números')
      .required('Código postal de origen requerido'),
    colonyName: yup.string().required('Colonia de origen requerido'),
  }),
  clientAddrDest: yup.object({
    zipCode: yup.string()
      .min(5, 'El código postal debe constar de  min. 5 números')
      .max(5, 'El código postal debe constar de  max. 5 números')
      .required('Código postal de destino requerido'),
    colonyName: yup.string().required('Colonia de destino requerido'),
  }),
  amountOfEnvelope: yup.number().integer()
    .default(0).min(0, 'Valor invalido: Rango de 0 - 1')
    .max(1, 'Solo se puede enviar 1 sobre en cada envió')
    .required('N° de sobres requerido. Valor min 0'),
  amountOfPackets: yup.number().integer()
    .default(0)
    .min(0, 'Valor invalido: Rango de 0 - 25')
    .max(25, 'Solo se puede cotizar max 25 paquetes')
    .required('N° de paquetes requerido. Valor min 0'),
  shipmentPktDetails: yup.array().of(yup.object({
    quantity: yup.number()
      .required('Cantidad. Campo obligatorio')
      .min(1, 'Min. cantidad de paquetes permitidos 1')
      .max(25, 'Max. cantidad de paquetes 25'),
    weight: yup.number()
      .required('Peso. Campo requerido')
      .min(1, 'Min. peso requerido es de 1KG')
      .max(20000, 'Max. peso soportado de 20000 kg'),
    longShip: yup.number()
      .required('Largo. Campo obligatorio')
      .min(1, 'Min. 1 CM de largo permitido'),
    widthShip: yup.number()
      .required('Anchura. campo requerido')
      .min(1, 'Min. 1 CM de Ancho permitido'),
    highShip: yup.number()
      .required('Altura. campo requerido')
      .min(1, 'Min. 1 CM de altura permitido'),
    shpCode: yup.string().required()
  })),
  shipmentEnvDetails: yup.object({ // details to envelope 
    quantity: yup.number()
      .min(1, 'Min. cantidad permitida 1')
      .max(20, 'Max. cantidad permitida 20'),
    weight: yup.number()
      .min(1).max(1),
    longShip: yup.number()
      .min(2),
    widthShip: yup.number()
      .min(2),
    highShip: yup.number()
      .min(1.5).max(1.5),
    shpCode: yup.string()
  }).nullable(),
  pickupOrder: yup.boolean().default(false).required(),
  acknowledgmentXT: yup.string().default('Sin acuse'),
  pickupDate: yup.date(),
  ocurreService: yup.boolean().default(false).required(),
}).required();

interface CalculatorProps {
  actions?: any;
  calculatorForm?: any;
  history?: any;
  calculatorResult?: any;
  branchOfficesRes?: any;
  profile?: any;
  list?: any
}

const carrierImage: { [key: string]: string } = {
  'EXPRESS': expressImg,
  'FEDEX': fedexImg,
  'DHL': dhlImg,
  'INABIT': inabitImg,
  'UPS': upsImg
}

const Calculator: React.FC<CalculatorProps> = (props) => {
  const { actions, calculatorForm, history, calculatorResult, branchOfficesRes, profile, list } = props;
  const { register, handleSubmit, formState: { errors }, control, watch, setValue, resetField, clearErrors, } = useForm<CalculatorFormInput>({
    resolver: yupResolver(schema)
  });
  const { fields, append, remove } = useFieldArray({ name: 'shipmentPktDetails', control });
  const numberOfPackets = parseInt(watch('amountOfPackets'))
  const numberOfEnvelopes = parseInt(watch('amountOfEnvelope'))
  const clientAddrOrigZipCode = watch('clientAddrOrig.zipCode')
  const clientAddrDestZipCode = watch('clientAddrDest.zipCode')
  const [coloniesOrig, setColoniesOrig] = useState<string[]>([])
  const [coloniesDest, setColoniesDest] = useState<string[]>([])
  const [origData, setOrigData] = useState([])
  const [destData, setDestData] = useState([])
  const [addressList, setAddressList] = useState([...list])
  const pickupOrder = watch('pickupOrder')
  const acknowledgmentXT = watch('acknowledgmentXT')
  const ocurreService = watch('ocurreService')
  const destColonyName = watch('clientAddrDest.colonyName')
  const originAddressSelected = watch('originSelected')
  const destAddressSelected = watch('destinationSelected')

  const sortArray = (x: any, y: any) => {
    if (x.nameOfIndividual < y.nameOfIndividual) { return -1; }
    if (x.nameOfIndividual > y.nameOfIndividual) { return 1; }
    return 0;
  }

  const acknowledgmentXTOptions = [
    {
      value: 'Sin acuse',
      label: 'Sin acuse'
    },
    {
      value:'XT',
      label: 'Acuse XT (COSTO EXTRA $150 PESOS)'
    },
  ]

  const addressBook = addressList
    .map((item: any) => ({
      key: item._id,
      value: JSON.stringify(item),
      text: `${item.nameOfIndividual}, ${item.address.colonyName}, ${item.address.state}, ${item.address.zipCode}`
    }))

  const exceedsMaxPacketsAllowed = (): boolean => {
    const pkts = watch('shipmentPktDetails');
    if (!pkts) return false;
    const sum = pkts.reduce((accumulator, object) => {
      return accumulator + parseInt(object.quantity.toString());
    }, 0);
    return sum > 25;
  }
  useEffect(() => {
    const data = [...list]
    if (data.length > 0) setAddressList(data.sort(sortArray))
  }, [list])

  useEffect(() => { actions.getAddresses() }, [])

  useEffect(() => {
    const newValPkt = numberOfPackets || 0;
    const oldVal = fields.length;
    let envelopeDetail = {
      highShip: 1.5,
      longShip: 35,
      quantity: numberOfEnvelopes,
      sequence: 0,
      shpCode: "1",
      weight: 1,
      widthShip: 24
    }
    if (numberOfEnvelopes > 0) setValue('shipmentEnvDetails', envelopeDetail)
    if (numberOfEnvelopes === 0) setValue('shipmentEnvDetails', null)
    if (newValPkt > oldVal) {
      for (let i = oldVal; i < newValPkt; i++) {
        append({ sequence: 0, shpCode: '2', highShip: undefined, longShip: undefined, quantity: 1, weight: undefined, widthShip: undefined });
      }
    } else {
      for (let i = oldVal; i > newValPkt; i--) {
        remove(i - 1);
      }
    }
  }, [append, fields, numberOfPackets, numberOfEnvelopes, remove, setValue]);

  useEffect(() => {
    const searchZipCode = async (zipcode: string, type: string) => {
      const res = await existPostalCode(zipcode)
      if (res.exists) {
        const data = getColonies(res.data)
        if (type === 'ORIGIN') { setColoniesOrig([...new Set(data)]); setOrigData(res.data) }
        if (type === 'DESTINATION') { setColoniesDest([...new Set(data)]); setDestData(res.data) }
      }
    }

    if (clientAddrOrigZipCode?.length === 5) searchZipCode(clientAddrOrigZipCode, 'ORIGIN')
    if (clientAddrDestZipCode?.length === 5) searchZipCode(clientAddrDestZipCode, 'DESTINATION')

  }, [originAddressSelected, destAddressSelected, clientAddrOrigZipCode, clientAddrDestZipCode, setValue])

  useEffect(() => {
    if (originAddressSelected) {
      setValue('clientAddrOrig.zipCode', JSON.parse(originAddressSelected).address.zipCode)
      setValue('clientAddrOrig.colonyName', JSON.parse(originAddressSelected).address.colonyName)
      clearErrors('clientAddrOrig')
    }
    if (destAddressSelected) {
      setValue('clientAddrDest.zipCode', JSON.parse(destAddressSelected).address.zipCode)
      setValue('clientAddrDest.colonyName', JSON.parse(destAddressSelected).address.colonyName)
      clearErrors('clientAddrDest')
    }
  }, [originAddressSelected, destAddressSelected, clientAddrOrigZipCode, clientAddrDestZipCode, setValue, clearErrors])

  const getQuote = async (data: CalculatorFormInput) => {
    actions.calculatorFormChange({ ...data, origData, destData })
    actions.getQuote({ ...data, origData, destData, history });
    if (ocurreService) {
      const destinationData = destData.find((e: any) => e.colonyName === destColonyName)
      actions.getOccureBranchOffices({ ...data, destinationData, history });
    }
  }

  const selectShipmentAndCreateShipment = (shipment: any) => {
    actions.calculatorFormChange({ shipmentSelect: shipment, shopifyOrder: calculatorForm.shopifyOrder, wooCommerceOrder: calculatorForm.wooCommerceOrder });
    history.push(paths.CREATE_ORDER)
  }

  const retryQuote = () => { actions.calculatorFormChange({ actionQuote: false }); }

  const clearOrigSelect = () => {
    resetField('originSelected')
    resetField('clientAddrOrig.zipCode')
    resetField('clientAddrOrig.colonyName')
  }

  const clearDestSelect = () => {
    resetField('destinationSelected')
    resetField('clientAddrDest.zipCode')
    resetField('clientAddrDest.colonyName')
  }


  return (
    <div>
      <Dimmer active={calculatorForm.loading} inverted>
        <Loader inverted>Buscando servicios disponibles....</Loader>
      </Dimmer>
      <div className="grid bg-white md:bg-white md:shadow-xl lg:p-4 sm:p-2 py-2 rounded-xl">

        <div className={`flex justify-center ${calculatorForm.actionQuote ? 'hidden' : ''}`}>
          <div className='flex xl:w-7/12 md-full justify-center'>
            <form onSubmit={handleSubmit(getQuote)} className="rounded-xl p-2 w-full">
              <h2 className="text-2xl font-bold text-center">Llena los siguientes campos para conocer las tarifas disponibles.</h2>
              <hr className="mt-1 mb-6 md:min-w-full " />
              <div className="grid grid-cols-1 mb-2 w-full">
                {/* Origin postal Code */}
                <div className='grid'>
                  <span className='text-xl font-bold mb-2'>Datos de origen: </span>
                  <div className="grid grid-cols-1">
                    <div className='grid grid-flow-col mb-6 border-2 rounded-lg'>
                      <select {...register('originSelected')}
                        className={`w-full rounded-lg  text-lg py-2 px-4 focus:outline-none ${errors.originSelected ? 'border-red-500' : ''}`}>
                        <option key="default" value="">Seleccionar origen de direcciones guardadas (Opcional)</option>
                        {addressBook.map((value: any) => (
                          <option key={value.key} value={value.value}>
                            {value.text}
                          </option>
                        ))}
                      </select>
                      {originAddressSelected && <button onClick={clearOrigSelect} type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>}
                    </div>
                  </div>
                  <div className='grid sm:grid-cols-1 lg:grid-cols-2 lg:space-x-2'>
                    <div className='grid'>
                      <input
                        className={`bg-transparent border-2 text-lg py-2 px-4 rounded-lg ${errors.clientAddrOrig?.zipCode ? 'border-red-600' : ''}`}
                        type="number"
                        maxLength={5}
                        {...register("clientAddrOrig.zipCode")}
                        placeholder='Código postal de origen'
                        defaultValue={calculatorForm.shopifyOrder?.shop?.zip || calculatorForm.wooCommerceOrder?.order?.shop?.zip || ''}
                      />
                      <span className='text-red-600 text-xs font-semibold h-5'>{errors.clientAddrOrig?.zipCode?.message}</span>
                    </div>
                    <div className='grid'>
                      <select
                        className={`bg-transparent border-2 text-lg py-2 px-4 rounded-lg ${errors.clientAddrOrig?.colonyName ? 'border-red-600' : ''}`}
                        {...register('clientAddrOrig.colonyName')}
                      >
                        <option hidden value="">Seleccionar Colonia</option>
                        {
                          coloniesOrig.map((option) => (
                            <option value={option} key={option} selected={originAddressSelected && JSON.parse(originAddressSelected).address.colonyName === option ? true : false}>{option}</option>
                          ))
                        }
                      </select>
                      <span className='text-red-600 text-xs font-semibold h-5'>{errors.clientAddrOrig?.colonyName?.message}</span>
                    </div>
                  </div>
                </div>
                {/* Destination postal Code */}
                <div className='grid'>
                  <span className='text-xl font-bold mb-2'>Datos de destino: </span>
                  <div className="grid grid-cols-1">
                    <div className='grid mb-6 grid grid-flow-col mb-6 border-2 rounded-lg'>
                      <select {...register('destinationSelected')}
                        className={`w-full rounded-lg text-lg py-2 px-4 focus:outline-none ${errors.destinationSelected ? 'border-red-500' : ''}`}>
                        <option key={""} value={""} >Seleccionar destino de direcciones guardadas(Opcional)</option>
                        {addressBook.map((value: any) => (
                          <option key={value.key} value={value.value}>
                            {value.text}
                          </option>
                        ))}
                      </select>
                      {destAddressSelected && <button onClick={clearDestSelect} type="button">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                          <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                        </svg>
                      </button>}
                    </div>
                  </div>
                  <div className='grid sm:grid-cols-1 lg:grid-cols-2 lg:space-x-2'>
                    <div className='grid'>
                      <input
                        className={`bg-transparent border-2 text-lg py-2 px-4 rounded-lg ${errors.clientAddrDest?.zipCode ? 'border-2 border-red-600' : ''}`}
                        type="number"
                        maxLength={5}
                        {...register("clientAddrDest.zipCode")}
                        placeholder='Código postal de destino'
                        defaultValue={calculatorForm.shopifyOrder?.order?.shipping_address?.zip || calculatorForm.wooCommerceOrder?.order?.shipping_address?.zip}
                      />
                      <span className='text-red-600 text-xs font-semibold h-5'>{errors.clientAddrDest?.zipCode?.message}</span>
                    </div>
                    <div className='grid'>
                      <select
                        className={`bg-transparent border-2 text-lg py-2 px-4 rounded-lg ${errors.clientAddrOrig?.colonyName ? 'border-red-600' : ''}`}
                        {...register('clientAddrDest.colonyName')}
                      >
                        <option hidden value="">Seleccionar Colonia</option>
                        {
                          coloniesDest.map((option) => (
                            <option value={option} key={option} selected={destAddressSelected && JSON.parse(destAddressSelected).address.colonyName === option ? true : false}>{option}</option>
                          ))
                        }
                      </select>
                      <span className='text-red-600 text-xs font-semibold h-5'>{errors.clientAddrDest?.colonyName?.message}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="grid lg:grid-cols-2 sm:grid-cols-1 my-2 lg:space-x-3">
                <div className='grid'>
                  <label className="text-gray-500 font-bold mb-3">N° de sobres (Max. 1)</label>
                  <input
                    className={`text-lg p-2 bg-transparent border-2 rounded-lg ${errors.amountOfEnvelope ? 'border-red-500' : ''}`}
                    type="number"
                    defaultValue={0}
                    min={0}
                    max={1}
                    {...register('amountOfEnvelope' as const, { min: 0, max: 20, required: true })}
                  />
                  <span className='text-red-600 font-semibold h-6'>{
                    errors.amountOfEnvelope?.type === 'typeError' ?
                      'Valor invalido: Rango de 0 - 1' :
                      errors.amountOfEnvelope?.message}
                  </span>
                </div>
                <div className='grid'>
                  <label className="text-gray-500 font-bold mb-3">N° de paquetes (Max. 25)</label>
                  <input
                    className={`text-lg p-2 bg-transparent border-2 rounded-lg ${errors.amountOfPackets ? 'border-red-600' : ''}`}
                    type="number"
                    defaultValue={calculatorForm.shopifyOrder?.order?.line_items.length || calculatorForm.wooCommerceOrder?.order?.line_items.length || 0}
                    min={0}
                    max={25}
                    {...register('amountOfPackets' as const, { min: 0, max: 10, required: true })}
                  />
                  <span className='text-red-600 font-semibold h-6'>
                    {
                      errors.amountOfPackets?.type === 'typeError' ?
                        'Valor únicos entre 0 - 25' :
                        errors.amountOfPackets?.message
                    }
                  </span>
                </div>
              </div>
              <div className="grid lg:grid-cols-2 sm:grid-cols-1 align-middle">
                <div className='grid'>
                  <div className="flex py-6 flex-col">
                    <div className="flex flex-row mb-1">
                      <div className="h-6 w-6 mr-2">
                        <input
                          className="h-6 w-6  border border-gray-300 rounded-sm bg-white self-start"
                          type="checkbox"
                          id="identificador"
                          {...register("pickupOrder" as const)}
                        />
                      </div>
                      <label className="text-gray-800 text-lg self-start">
                        Solicitar recolección de paquete o sobre (Paquetexpress)
                      </label>
                    </div>

                    <div className="flex flex-row mb-1">
                      <input
                        className="h-6 w-6 mr-2 border rounded-sm bg-white "
                        type="checkbox"
                        id="identificador"
                        {...register("ocurreService" as const)}
                      />
                      <label className="inline-block text-gray-800 text-center text-lg">
                        Servicio OCURRE (Paquetexpress)
                      </label>
                    </div>

                    {profile.canGenerateXT ? 
                    <div className='flex flex-row mt-4 mb-1 items-center' >
                      <p className="inline-block text-gray-800 text-center text-lg mr-4">
                        Acuse de recibo
                      </p>
                      <select
                        className='bg-transparent border-2 py-2 px-4 rounded-lg flex p-0 w-64'
                        {...register('acknowledgmentXT')}
                        defaultValue='Sin acuse'
                      >
                        {
                          acknowledgmentXTOptions.map((option) => (
                            <option value={option.value} key={option.value} selected={acknowledgmentXT === option.value }>{option.label}</option>
                          ))
                        }
                      </select>
                    </div>
                    : <></>
                  }
                  </div>
                </div>
                <div className="grid">
                  {
                    pickupOrder ?
                      <div className='flex mt-4 p-0 w-full'>
                        <Controller
                          name='pickupDate'
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              message: 'Fecha de recolección requerida',
                            },
                          }}
                          render={({
                            field: {
                              name, onBlur, value, onChange
                            },
                          }) => (
                            <TextField
                              required={true}
                              helperText={'Seleccionar fecha de recolección'}
                              id="datetime-local"
                              label="Fecha y hora"
                              type="datetime-local"
                              onBlur={onBlur}
                              onChange={onChange}
                              value={value}
                              sx={{ width: '100%' }}
                              InputLabelProps={{
                                shrink: true,
                              }}
                            />
                          )}
                        />
                      </div>
                      :
                      <></>
                  }
                </div>
              </div>
              <div className="grid grid-cols-1 sm:w-full overflow-auto">
                {
                  isNaN(numberOfPackets) || numberOfPackets <= 0 ?
                    <></>
                    :
                    <table className="table-auto">
                      <thead>
                        <tr>
                          <th className='border-2 border-gray-300 text-2xs h-16'>
                            <span className='uppercase'>
                              N° de Paquetes
                            </span>
                            <br />
                            <span className='text-gray-500'>
                              Max. 25
                            </span>
                          </th>
                          <th className='border-2 border-gray-300 text-2xs h-16'>
                            <span className='uppercase'>
                              Peso por paquete
                            </span>
                            <br />
                            <span className='text-gray-500'>
                              KG
                            </span></th>
                          <th className='border-2 border-gray-300 text-2xs h-16'>
                            <span className='uppercase'>
                              Dimsensiones por paquete
                            </span>
                            <br />
                            <span className='text-gray-500'>
                              L × An × Al
                            </span></th>
                        </tr>
                      </thead>
                      <tbody>
                        {fields.map((item, i) => (
                          <>
                            <tr>
                              <td className='border-2 border-gray-300'>
                                <div className="m-auto w-24">
                                  <input
                                    className={`text-lg text-center h-16 pl-4 bg-transparent w-24 focus:outline-none ${errors.shipmentPktDetails?.[i]?.quantity ? 'border-l-4 border-red-500' : ''}`}
                                    type='number'
                                    defaultValue={1}
                                    max={25}
                                    {...register(`shipmentPktDetails.${i}.quantity`, { required: true })}
                                  />
                                </div>
                              </td>
                              <td className='border-2 border-gray-300'>
                                <div className="m-auto w-24">
                                  <input
                                    className={`text-lg text-center h-16 pl-4 bg-transparent w-14 focus:outline-none ${errors.shipmentPktDetails?.[i]?.weight ? 'border-l-4 border-red-500' : ''}`}
                                    type='number'
                                    {...register(`shipmentPktDetails.${i}.weight`)}
                                  />
                                  <span className='text-sm text-gray-400 font-bold mr-1'>KG</span>
                                </div>
                              </td>
                              <td className='border-2 border-gray-300 lg:px-2'>
                                <div className="m-auto w-52">
                                  <input
                                    className={`text-lg sm:p-0 text-center h-16 bg-transparent w-12 focus:outline-none ${errors.shipmentPktDetails?.[i]?.longShip ? 'border-l-4 border-red-500' : ''}`}
                                    type='number'
                                    {...register(`shipmentPktDetails.${i}.longShip`)}
                                  />
                                  <span className='text-sm text-gray-400 font-bold lg:mr-1 w-4'>X</span>
                                  <input
                                    className={`text-lg sm:p-0 text-center h-16 bg-transparent w-12 focus:outline-none ${errors.shipmentPktDetails?.[i]?.widthShip ? 'border-l-4 border-red-500' : ''}`}
                                    type='number'
                                    {...register(`shipmentPktDetails.${i}.widthShip`)}
                                  />
                                  <span className='text-sm text-gray-400 font-bold lg:mr-1 w-4'>X</span>
                                  <input
                                    className={`text-lg text-center sm:p-0 h-16 bg-transparent w-12 focus:outline-none ${errors.shipmentPktDetails?.[i]?.highShip ? 'border-l-4 border-red-500' : ''}`}
                                    type='number'
                                    {...register(`shipmentPktDetails.${i}.highShip`)}
                                  />
                                  <span className='text-base text-gray-400 font-bold w-4'>CM</span>
                                </div>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={3}>
                                <span className='text-red-600 font-semibold'>{
                                  errors.shipmentPktDetails?.[i]?.quantity?.type === 'typeError' ?
                                    'Cantidad paq. Valor no permitido' :
                                    errors.shipmentPktDetails?.[i]?.quantity?.message}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={3}>
                                <span className='text-red-600 font-semibold'>{
                                  errors.shipmentPktDetails?.[i]?.weight?.type === 'typeError' ?
                                    'Peso paq. Valor no permitido' :
                                    errors.shipmentPktDetails?.[i]?.weight?.message}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={3}>
                                <span className='text-red-600 font-semibold'>{
                                  errors.shipmentPktDetails?.[i]?.longShip?.type === 'typeError' ?
                                    'Largo paq. Valor no permitido' :
                                    errors.shipmentPktDetails?.[i]?.longShip?.message}
                                </span>
                              </td>
                            </tr>
                            <tr>
                              <td colSpan={3}>
                                <span className='text-red-600 font-semibold'>{
                                  errors.shipmentPktDetails?.[i]?.widthShip?.type === 'typeError' ?
                                    'Anchura paq. Valor no permitido' :
                                    errors.shipmentPktDetails?.[i]?.widthShip?.message}
                                </span>

                              </td>
                            </tr>
                            <tr>
                              <td colSpan={3}>
                                <span className='text-red-600 font-semibold'>{
                                  errors.shipmentPktDetails?.[i]?.highShip?.type === 'typeError' ?
                                    'Altura paq. Valor no permitido' :
                                    errors.shipmentPktDetails?.[i]?.highShip?.message}
                                </span>
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </table>
                }
              </div>
              <input
                type="submit"
                value="Cotizar"
                disabled={(numberOfEnvelopes === 0 && numberOfPackets === 0) || Object.keys(errors).length >= 1 || exceedsMaxPacketsAllowed()}
                className={`bg-primary-inabit-red mt-4 py-4 text-2xl text-white rounded-xl w-full ${(numberOfEnvelopes === 0 && numberOfPackets === 0) || Object.keys(errors).length >= 1 || exceedsMaxPacketsAllowed() ? 'opacity-50 cursor-not-allowed' : 'hover:bg-primary-inabit-coffe cursor-pointer'}`}
              />
              <span className='text-red-600 text-xs font-semibold h-5'>{exceedsMaxPacketsAllowed() ? 'Supera la maxima cantidad de paquetes permitidos' : ''}</span>

            </form>
          </div>
        </div>


        {/* Table of services */}
        {
          calculatorResult.length > 0 && calculatorForm.actionQuote
            ?
            <div className="grid-col-1 block sm:hidden lg:block">
              <div className="p-2">
                <div className="relative md:mb-8 text-center">
                  <h3 className="text-xl font-bold text-center pt-2 mb-8 uppercase">Servicios disponibles</h3>
                  <button className="relative md:absolute md:top-0 md:right-10 bg-primary-inabit-blue hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded-2xl inline-flex items-center" onClick={retryQuote}>
                    <span className='mr-4 text-white'>Volver a Cotizar</span>
                    <Image src={CalculatoSvg} rounded width="25" />
                  </button>
                  {
                    branchOfficesRes.success ?
                      <></>
                      :
                      <>
                        <h3 className='font-bold text-center'>AVISO: Existen servicios disponibles para esta zona. Sin embargo, el servicio OCURRE no esta diponible</h3>
                        <h3 className='font-bold text-center'>Vuelva a cotizar sin escoger la opción OCURRE</h3>
                      </>
                  }
                  <hr className="md:mb-10 md:mt-6 md:min-w-full" />
                </div>
                <div className="grid grid-cols-1 overflow-auto ">
                  <div className={`grid grid-flow-col-dense grid-cols-${profile.canSeeQuoteTotalPrice ? '6' : '5'} invisible md:visible text-center`}>
                    <div className="grid border border-slate-600 py-2 font-bold uppercase">Paquetería</div>
                    <div className="grid border border-slate-600 py-2 font-bold uppercase">Servicio</div>
                    <div className="grid border border-slate-600 py-2 font-bold uppercase">Entrega estimada</div>
                    <div className="grid border border-slate-600 py-2 font-bold uppercase">Entrega</div>
                    {/* <div className="grid border border-slate-600 py-2 font-bold uppercase">Descuento</div>
                    <div className="grid border border-slate-600 py-2 font-bold uppercase">Precio</div> */}
                    {profile.canSeeQuoteTotalPrice && <div className="grid border border-slate-600 py-2 font-bold uppercase">Precio final</div>}
                    <div className="grid border border-slate-600 py-2 font-bold uppercase"></div>
                  </div>
                  {
                    calculatorResult.map((item: any, i: any) => (
                      <div className={`grid ${profile.canSeeQuoteTotalPrice ? 'grid-cols-1' : ''} md:grid-cols-${profile.canSeeQuoteTotalPrice ? '6' : '5'} text-center hover:bg-gray-100 border border-slate-600 rounded-3xl my-4 shadow-sm md:border-b md:m-0 md:shadow-none md:rounded-none bg-white md:bg-transparent`} key={i}>
                        <div className="grid md:border md:border-slate-600 py-2">
                          <Image id='logoCompany' src={carrierImage[item.carrier]} rounded width="60" />
                        </div>
                        <div className="grid md:border md:border-slate-600 py-2 justify-items-center content-center">
                          <div className="flex text-center ">
                            <span className='block md:hidden uppercase font-bold mr-1'>Servicio: </span>
                            <span className='text-sm'>{
                              item.carrier === 'EXPRESS' || item.carrier === 'FEDEX'
                                ? item.serviceName.replace('Express', 'Blue bit').replace('FedEx', 'Purple bit')
                                : item.serviceName
                              }
                            </span>
                          </div>
                        </div>
                        <div className="grid md:border md:border-slate-600 py-2 justify-items-center content-center">
                          <div className="flex text-center">
                            <span className="block md:hidden uppercase font-bold mr-1">Entrega estimada:</span>
                            <span className='text-sm'>{item.promiseDate || '----'}</span>
                          </div>
                        </div>
                        <div className="grid md:border md:border-slate-600 py-2 justify-items-center content-center">
                          <div className="flex text-center">
                            <span className="block md:hidden uppercase font-bold mr-1">Entrega:</span>
                            <span className='text-sm'>{ocurreService && item.carrier === "EXPRESS" ? "En oficina" : "En domicilio"}</span>
                          </div>
                        </div>
                        {/* <div className="grid md:border md:border-slate-600 py-2 justify-items-center content-center">
                          <div className="flex text-center">
                            <span className="block md:hidden uppercase font-bold mr-1">Descuento:</span>
                            <span className='text-sm'>{profile?.discount} %</span>
                          </div>
                        </div>
                        <div className="grid md:border md:border-slate-600 py-2 justify-items-center content-center">
                          <div className="flex text-center">
                            <span className="block md:hidden uppercase font-bold mr-1">Precio:</span>
                            <span className='text-sm'>{`${item.clientPrice} ${item.currency}`}</span>
                          </div>
                        </div> */}
                        {profile.canSeeQuoteTotalPrice && <div className="grid md:border md:border-slate-600 py-2 justify-items-center content-center">
                          <div className="flex text-center">
                            <span className="block md:hidden uppercase font-bold mr-1">Precio final:</span>
                            <span className='text-sm'>{profile.showFinalPriceByService[item.carrier.toLowerCase()] ? `${item.priceWithDiscount} ${item.currency}` : '-'}</span>
                          </div>
                        </div>}
                        <div className="grid md:border md:border-slate-600 py-2 justify-items-center content-center">
                          <div className='w-full'>
                            <button
                              className={`w-5/6 bg-primary-inabit-red text-white font-bold py-2 px-4 rounded-full ${!branchOfficesRes.success ? 'opacity-50 cursor-not-allowed' : 'hover:bg-primary-inabit-coffe'}`}
                              onClick={() => selectShipmentAndCreateShipment(item)}
                              disabled={!branchOfficesRes.success}
                            >
                              Crear guía
                            </button>
                          </div>
                        </div>
                      </div>
                    ))
                  }
                </div>
              </div>
            </div>
            :
            calculatorForm.actionQuote && calculatorResult.length <= 0
              ?
              <div className='content-center text-center text-center p-8'>
                <div className="relative mb-8">
                  <button className="absolute top-0 right-10 bg-primary-inabit-blue hover:bg-grey text-grey-darkest font-bold py-2 px-4 rounded-2xl inline-flex items-center" onClick={retryQuote}>
                    <span className='mr-4 text-white'>Volver a Cotizar</span>
                    <Image src={CalculatoSvg} rounded width="25" />
                  </button>
                  <h3 className="text-xl font-bold text-center pt-2 mb-8 uppercase">Sin servicios disponibles</h3>
                  <hr className="mb-8 md:min-w-full" />
                </div>
                <div className="text-center">
                  <Image src={DeliveryTruck} rounded width="150" className='m-auto' />
                  <span className="font-bold">No hay servicios disponibles para esta área en estos momentos, vuelve a intentar más tarde</span>
                </div>
              </div>
              :
              <></>
        }
      </div >
    </div >
  )
}

const withConnect = connect(
  selectState(
    'calculator.calculatorForm',
    'calculator.calculatorResult',
    'calculator.branchOfficesRes',
    'app.profile',
    'addressBook.list'
  ),
  actionProps({ ...calculatorActions, ...bookAddressActions }),
);

export default withRouter(withConnect(Calculator));
