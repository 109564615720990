import React, { useState, useMemo, useCallback } from 'react';
import { Autocomplete, TextField, Chip, Box, Typography } from '@mui/material';
import { debounce } from 'lodash';

interface SelectorSearcherProps {
  label?: string;
  placeholder?: string;
  sectionName?: string;
  options: string[] | null;
  handleChange: (value: string[]) => void;
  limit?: number;
  mainAccount?: string;
}

const SelectorSearcher = ({
  options,
  label,
  placeholder = '-',
  sectionName,
  handleChange,
  limit,
  mainAccount,
}: SelectorSearcherProps) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const [inputValue, setInputValue] = useState('');

  const handleSelect = (event: React.SyntheticEvent, value: string[]) => {
    if (!limit || value.length <= limit) {
      setSelectedOptions(value);
      handleChange(value);
    }
  };

  const handleChipClick = (optionToRemove: string) => {
    const newOptions = selectedOptions.filter(
      option => option !== optionToRemove,
    );
    setSelectedOptions(newOptions);
    handleChange(newOptions);
  };

  const debouncedSetInputValue = useCallback(
    debounce((value: string) => setInputValue(value), 300),
    [],
  );

  const filteredOptions = useMemo(() => {
    if (!inputValue || !options) return options ?? [];
    const lowercasedInput = inputValue.toLowerCase();
    return options.filter(option =>
      option.toLowerCase().includes(lowercasedInput),
    );
  }, [inputValue, options]);

  return (
    <Box width='50%'>
      {sectionName && <Typography>{sectionName}</Typography>}
      <Autocomplete
        multiple
        options={filteredOptions}
        value={selectedOptions}
        getOptionLabel={option =>
          option === mainAccount ? `${option} (PRINCIPAL)` : option
        }
        onChange={handleSelect}
        onInputChange={(event, value) => debouncedSetInputValue(value)}
        renderTags={() => null}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            placeholder={placeholder}
          />
        )}
      />
      <Box
        mt={2}
        display='flex'
        flexWrap='wrap'
        gap={1}
      >
        {selectedOptions.map((option, index) => {
          const optionLabel =
            option === mainAccount ? `${option} (PRINCIPAL)` : option;
          return (
            <Chip
              key={index}
              label={optionLabel}
              onDelete={() => handleChipClick(option)}
              sx={{ backgroundColor: '#FA3A49', color: 'white' }}
              clickable
            />
          );
        })}
      </Box>
      {limit && selectedOptions.length >= limit && (
        <Typography
          color='error'
          variant='caption'
        >
          Haz alcanzado el límite de opciones de {limit}.
        </Typography>
      )}
    </Box>
  );
};

export default SelectorSearcher;
