import axios from 'axios';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import Chip from '@mui/material/Chip';
import { connect } from 'react-redux';
import Tooltip from '@mui/material/Tooltip';
import { shipmentActions } from './reducer';
import { withRouter } from 'react-router-dom';
import Modal from '../../commons/Components/Modal';
import React, { useEffect, useState } from 'react';
import { getChipColor } from '../../utils/guideStatus';
import { getItemInStorage } from '../../utils/functions';
import Facturama from '../../commons/Components/Facturama';
import SupportPayment from '../../commons/Components/SupportPayment';
import { actionProps, selectState } from '../../utils/reduxActions';
import { invoiceActions } from '../../commons/Components/Facturama/reducer';
import { supportPaymentActions } from '../../commons/Components/SupportPayment/reducer';
import { DataGrid, GridColDef, GridToolbarContainer } from '@mui/x-data-grid';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { SERVER_URL, APPLICATION_KEY, SECRET_KEY } from '../../utils/constants';
import SearchInputMultipleChoice from '../../commons/Components/SearchInputMultipleChoice';

interface Guide {
  id: string;
  serviceName: string;
  destination: string;
  trackingNumber: string;
  createdAt: string;
  actions: any;
  guideUrl: string;
  guideUrl6x4?: string;
  userEmail?: string;
  transaction: {
    _id: string;
  };
}

interface ShipmentData {
  data: Guide[];
  allEmails: string[];
}

interface ShipmentsProps {
  actions?: any;
  controls?: any;
  history?: any;
  list?: ShipmentData;
  report?: any;
  invoiceControls?: any;
  supportPaymentRes?: any;
  supportPaymentFields?: any;
  profile?: any;
  cancelGuide?: any;
}

const CustomToolbar = () => {
  return <GridToolbarContainer></GridToolbarContainer>;
};

const Shipments: React.FC<ShipmentsProps> = props => {
  const {
    actions,
    list,
    controls,
    invoiceControls,
    report,
    profile,
    supportPaymentFields,
  } = props;
  let [isOpenModalSupportPayment, setIsOpenModalSupportPayment] =
    useState(false);
  let [isOpenModalInvoice, setIsOpenModalInvoice] = useState(false);
  let [isOpenModalReport, setIsOpenModalReport] = useState(false);
  const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
  const [reportFromDate, setReportFromDate] = useState<Date | null>(null);
  const [reportToDate, setReportToDate] = useState<Date | null>(null);
  const [emailsReport, setEmailsReport] = useState<String | null>(null);
  const [emailsSelectedForFilter, setEmailsSelectedForFilter] = useState<
    String[]
  >([]);
  const [filteredData, setFilteredData] = useState<Guide[]>([]);
  const [userEmail, setUserEmail] = useState<string>('');

  const openModal = () => {
    setIsOpenModalInvoice(true);
  };
  const openModalSupportPayment = () => {
    setIsOpenModalSupportPayment(true);
  };
  const createInvoice = (data: any) => {
    actions.fieldsChanged({
      transactionIds: selectedRows.map(e => e.transactionId),
      paymentForm: data.paymentForm,
      guides: selectedRows.map(e => e.guide),
      serviceName: selectedRows.map(e => e.serviceName),
    });
    openModal();
  };

  const supportPayment = (data: any) => {
    actions.supportPaymentFieldsChanged({
      invoiceId: data.invoice?.Id,
      amount: data.transaction.amount,
      invoice: data.invoice,
    });
    openModalSupportPayment();
  };

  const requestReport = () => {
    const emailsFilter =
      emailsSelectedForFilter.length > 0
        ? emailsSelectedForFilter
        : (list?.allEmails ?? []).length > 0
        ? list?.allEmails
        : [userEmail];
    actions.getReport({
      filters: {
        dateFrom: reportFromDate,
        dateTo: reportToDate,
        emails: emailsReport?.split(',').map(e => e.trim()),
        emailsSelectedForFilter: emailsFilter,
      },
    });
    setReportFromDate(null);
    setReportToDate(null);
  };

  const getInvoiceByFormat = async (format: string, data: any) => {
    const cfdiId = data?.Id || data?.transaction?.invoiceId;
    const fileName = data?.Folio || cfdiId;
    const token = getItemInStorage('token');
    try {
      const response = await axios.get(
        `${SERVER_URL}/invoice/format?format=${format}&id=${cfdiId}`,
        {
          headers: {
            application: APPLICATION_KEY,
            secret: SECRET_KEY,
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.data.data.Content) {
        const byteCharacters = atob(response.data.data.Content);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], {
          type: `application/${format}`,
        });

        const fileURL = URL.createObjectURL(blob);
        window.open(fileURL, '_blank');

        const downloadLink = document.createElement('a');
        downloadLink.href = fileURL;
        downloadLink.download = `FacturaFolio_${fileName}.${format}`;
        downloadLink.click();

        URL.revokeObjectURL(fileURL);
      } else {
        Swal.fire({
          title: 'No se encontró factura',
          text: 'Favor de contactar al administrador',
          confirmButtonColor: '#3085d6',
          confirmButtonText: 'Ok',
        });
      }
    } catch (error) {
      Swal.fire({
        title: 'Ocurrió un error',
        text: 'Por favor, inténtalo de nuevo',
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'Ok',
      });
    }
  };

  const handlerCancelInvoice = (data: any) => {
    Swal.fire({
      title: 'Desea cancelar la Factura?',
      text: '¡No podrás revertir esto!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, cancelar!',
      cancelButtonText: 'No, abortar!',
      showLoaderOnConfirm: true,
      preConfirm: () => {
        const token = getItemInStorage('token');
        Swal.showLoading();
        axios
          .post(
            `${SERVER_URL}/invoice/cancel`,
            { id: data.transaction._id },
            {
              headers: {
                application: APPLICATION_KEY,
                secret: SECRET_KEY,
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
              },
            },
          )
          .then(res => {
            Swal.fire(
              'Factura cancelada!',
              'Tu factura ha sido eliminada correctamente.',
              'success',
            ).then(() => {
              window.location.reload();
            });
          })
          .catch(err => {
            console.log(err.message);
            Swal.fire(
              'Error!',
              'Se ha producido un error mientras se cancelaba la factura. vuelve a intentarlo mas tarde.',
              'error',
            );
          });
      },
    });
  };

  const handleEmailsSelection = (value: string[]) => {
    setEmailsSelectedForFilter(prevState => {
      const newSelectedEmails = value;
      return newSelectedEmails;
    });
  };

  useEffect(() => {
    if (profile && profile.contact && profile.contact.email)
      setUserEmail(profile?.contact.email);
  }, [profile]);

  useEffect(() => {
    if (emailsSelectedForFilter.length) {
      const shipmentsFiltered = list?.data.filter(item => {
        return (
          item.userEmail && emailsSelectedForFilter.includes(item.userEmail)
        );
      });
      setFilteredData(shipmentsFiltered ?? []);
    } else {
      setFilteredData(list?.data || []);
    }
  }, [emailsSelectedForFilter, list]);

  useEffect(() => {
    actions.getShipmentList();
  }, []);

  useEffect(() => {
    if (invoiceControls.success) {
      setIsOpenModalInvoice(false);
      actions.getShipmentList();
    }
  }, [invoiceControls.success, actions]);

  console.log(supportPaymentFields);

  useEffect(() => {
    if (supportPaymentFields.success) {
      setIsOpenModalSupportPayment(false);
      actions.getShipmentList();
    }
  }, [supportPaymentFields.success, actions]);

  useEffect(() => {
    if (report.success) {
      Swal.fire(
        'Reporte Solicitado',
        `Tu reporte se esta generando, Revisa el/los correo/s '${
          emailsReport
            ? emailsReport?.split(',').map(e => e.trim())
            : profile?.contact.email
        }' en unos minutos. Si no lo encuentras en tu bandeja de entrada, revisa tu bandeja de spam`,
        'success',
      );
      setIsOpenModalReport(false);
      setEmailsReport(null);
    }
  }, [report, actions]);

  const columns: GridColDef[] = [
    {
      field: 'id',
      headerName: 'ID. de orden',
      minWidth: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'text-lg text-bold',
    },
    {
      field: 'userEmail',
      headerName: 'Usuario',
      minWidth: 200,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'text-lg text-bold',
      valueGetter: ({ value }) =>
        userEmail && value === userEmail ? '' : value,
    },
    {
      field: 'serviceName',
      headerName: 'Paquetería',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 100,
      headerClassName: 'text-lg text-bold',
      valueGetter: ({ value }) =>
        value
          .replace('EXPRESS', 'Blue bit')
          .replace('FEDEX', 'Purple bit')
          .replace('DHL', 'Yellow bit')
          .replace('INABIT', 'Orange bit')
          .replace('UPS', 'Brown bit'),
    },
    {
      field: 'destination',
      headerName: 'Destinatario',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 220,
      headerClassName: 'text-lg text-bold',
    },
    {
      field: 'trackingNumber',
      headerName: 'No. Rastreo',
      type: 'number',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      headerClassName: 'text-lg text-bold',
    },
    {
      field: 'trackStatus',
      headerName: 'status',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 180,
      headerClassName: 'text-lg text-bold',
      renderCell: data => {
        const chip = getChipColor(data.row.internalStatus);
        return (
          data.row.trackStatus && (
            <Tooltip
              title={data.row.trackStatus}
              arrow
            >
              <Chip
                label={data.row.trackStatus}
                color={chip.color}
                variant='outlined'
                icon={chip.icon}
              />
            </Tooltip>
          )
        );
      },
    },
    {
      field: 'transaction.invoiced',
      headerName: 'Facturado',
      type: 'boolean',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 120,
      headerClassName: 'text-lg text-bold',
      valueGetter: (params: any) => params.row.transaction.invoiced,
    },
    {
      field: 'transaction.invoiceId',
      headerName: 'ID. Factura',
      type: 'string',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 150,
      headerClassName: 'text-lg text-bold',
      valueGetter: (params: any) => params.row.transaction.invoiceId,
    },
    {
      field: 'invoice.PaymentTerms',
      headerName: 'Forma de pago Facturada',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 120,
      headerClassName: 'text-lg text-bold',
      renderCell: data => {
        return (
          data.row.invoice?.PaymentTerms && (
            <Tooltip
              title={data.row.invoice.PaymentTerms}
              arrow
            >
              <span>
                {data.row.transaction.invoiced && data.row.invoice.PaymentTerms}
              </span>
            </Tooltip>
          )
        );
      },
    },
    {
      field: 'invoice.supportPayment',
      headerName: 'Complemento de Pago',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      minWidth: 120,
      headerClassName: 'text-lg text-bold',
      valueGetter: (params: any) => params.row.invoice?.supportPayment,
    },
    {
      field: 'createdAt',
      headerName: 'Fecha de creación',
      type: 'date',
      minWidth: 180,
      sortable: false,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'text-lg text-bold',
      valueGetter: ({ value }) =>
        value &&
        moment(value).tz('America/Mexico_City').format('DD/MM/YYYY, h:mm:ss a'),
    },
    {
      field: 'actions',
      headerName: 'Acciones',
      flex: 1,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'text-lg text-bold',
      sortable: false,
      minWidth: 200,
      renderCell: data => {
        return (
          <div className='grid grid-cols-4 grid-flow-col gap-1 grid-flow-row-dense'>
            {data.row.upsLabelUrl && data.row.serviceName === 'UPS' && (
              <Tooltip
                title={'Descargar etiqueta'}
                arrow
              >
                <div className='bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover rounded-lg h-12 shadow flex justify-center items-center'>
                  <a
                    className='text-center p-4 text-white hover:text-white'
                    href={data.row.upsLabelUrl}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke-width='1.5'
                      stroke='currentColor'
                      className='w-6 h-6'
                    >
                      <path
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        d='M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z'
                      />
                    </svg>
                  </a>
                </div>
              </Tooltip>
            )}
            {data.row.upsReceiptUrl && data.row.serviceName === 'UPS' && (
              <Tooltip
                title={'Descargar ticket'}
                arrow
              >
                <div className='bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover rounded-lg h-12 shadow flex justify-center items-center'>
                  <a
                    className='text-center p-4 text-white hover:text-white'
                    href={data.row.upsReceiptUrl}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke-width='1.5'
                      stroke='currentColor'
                      className='w-6 h-6'
                    >
                      <path
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        d='M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z'
                      />
                    </svg>
                  </a>
                </div>
              </Tooltip>
            )}
            {data.row.guideUrl && data.row.serviceName === 'DHL' && (
              <Tooltip
                title={'Descargar guía'}
                arrow
              >
                <div className='bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover rounded-lg h-12 shadow flex justify-center items-center'>
                  <a
                    className='text-center p-4 text-white hover:text-white'
                    href={`data:application/pdf;base64,${data.row.guideUrl}`}
                    target='_blank'
                    rel='noreferrer'
                    download={`Guia-${data.row.trackingNumber}`}
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke-width='1.5'
                      stroke='currentColor'
                      className='w-6 h-6'
                    >
                      <path
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        d='M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z'
                      />
                    </svg>
                  </a>
                </div>
              </Tooltip>
            )}
            {data.row.guideUrl && data.row.serviceName !== 'DHL' && (
              <Tooltip
                title={'Descargar guía'}
                arrow
              >
                <div className='bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover rounded-lg h-12 shadow flex justify-center items-center'>
                  <a
                    className='text-center p-4 text-white hover:text-white'
                    href={data.row.guideUrl}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke-width='1.5'
                      stroke='currentColor'
                      className='w-6 h-6'
                    >
                      <path
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        d='M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z'
                      />
                    </svg>
                  </a>
                </div>
              </Tooltip>
            )}
            {data.row.acknowledgmentXT &&
              data.row.serviceName === 'EXPRESS' && (
                <Tooltip
                  title={'Regreso Acuse XT'}
                  arrow
                >
                  <div className='bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover rounded-lg h-12 shadow flex justify-center items-center'>
                    <a
                      className='text-center p-4 text-white hover:text-white'
                      href={data.row.guideUrl.replace(
                        data.row.trackingNumber,
                        data.row.acknowledgmentXT,
                      )}
                      target='_blank'
                      rel='noreferrer'
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke-width='1.5'
                        stroke='currentColor'
                        className='w-6 h-6'
                      >
                        <path
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          d='M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z'
                        />
                      </svg>
                    </a>
                  </div>
                </Tooltip>
              )}
            {data.row.guideUrl6x4 && (
              <Tooltip
                title={'Descargar guía térmica'}
                arrow
              >
                <div className='bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover rounded-lg h-12 shadow flex justify-center items-center'>
                  <a
                    className='text-center p-4 text-white hover:text-white'
                    href={data.row.guideUrl6x4}
                    target='_blank'
                    rel='noreferrer'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      fill='none'
                      viewBox='0 0 24 24'
                      stroke-width='1.5'
                      stroke='currentColor'
                      className='w-6 h-6'
                    >
                      <path
                        stroke-linecap='round'
                        stroke-linejoin='round'
                        d='M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m.75 12l3 3m0 0l3-3m-3 3v-6m-1.5-9H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z'
                      />
                    </svg>
                  </a>
                </div>
              </Tooltip>
            )}
            {data.row.invoice?.PaymentTerms === '99 - Por definir' &&
              !data.row.invoice?.supportPayment && (
                <Tooltip
                  title={'Complemento de Pago'}
                  arrow
                >
                  <div className=''>
                    <button
                      className={`${
                        !getChipColor(data.row.internalStatus)
                          ? 'bg-primary-inabit-disabled'
                          : 'bg-primary-inabit-yellow hover:bg-primary-inabit-yellow-hover'
                      } text-center p-2 text-white hover:text-white w-full h-full flex justify-center items-center rounded-lg`}
                      onClick={() => supportPayment(data.row)}
                      disabled={!getChipColor(data.row.internalStatus)}
                    >
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        fill='none'
                        viewBox='0 0 24 24'
                        stroke-width='1.5'
                        stroke='currentColor'
                        className='w-6 h-6'
                      >
                        <path
                          stroke-linecap='round'
                          stroke-linejoin='round'
                          d='M2.25 18.75a60.07 60.07 0 0 1 15.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 0 1 3 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 0 0-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 0 1-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 0 0 3 15h-.75M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm3 0h.008v.008H18V10.5Zm-12 0h.008v.008H6V10.5Z'
                        />
                      </svg>
                    </button>
                  </div>
                </Tooltip>
              )}
          </div>
          // <div className="dropdown">
          //   <button className="dropbtn">Actions</button>
          //   <div className="dropdown-content">
          //     <a href="#"><i className="fa fa-folder-open fa-icon"></i>Open</a>
          //     <a href="#"><i className="fa fa-print fa-icon"></i>Print</a>
          //     <a href="#"><i className="fa fa-share-alt fa-icon"></i>Share</a>
          //     <a href="#"><i className="fa fa-save fa-icon"></i>Save as...</a>
          //     <a href="#"><i className="fa fa-trash-alt fa-icon"></i>Delete</a>
          //   </div>
          // </div>
        );
      },
    },
    {
      field: 'invoice',
      headerName: 'Facturación',
      flex: 1,
      filterable: false,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'text-lg text-bold',
      sortable: false,
      minWidth: 200,
      renderCell: data => {
        return data.row.transaction.invoiced &&
          userEmail &&
          userEmail === data.row.userEmail ? (
          <div className='grid grid-cols-4 grid-flow-col gap-1 place-content-center'>
            <Tooltip
              title={'Cancelar Factura'}
              arrow
            >
              <div>
                <button
                  className={`${
                    !getChipColor(data.row.internalStatus)
                      ? 'bg-primary-inabit-disabled'
                      : 'bg-red-500 hover:bg-red-600'
                  } text-center p-2 text-white hover:text-white w-full h-full flex justify-center items-center rounded-lg`}
                  onClick={() => handlerCancelInvoice(data.row)}
                  disabled={!getChipColor(data.row.internalStatus)}
                >
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    fill='none'
                    viewBox='0 0 24 24'
                    strokeWidth='1.5'
                    stroke='currentColor'
                    className='w-6 h-6'
                  >
                    <path
                      strokeLinecap='round'
                      strokeLinejoin='round'
                      d='M20.25 7.5l-.625 10.632a2.25 2.25 0 01-2.247 2.118H6.622a2.25 2.25 0 01-2.247-2.118L3.75 7.5m6 4.125l2.25 2.25m0 0l2.25 2.25M12 13.875l2.25-2.25M12 13.875l-2.25 2.25M3.375 7.5h17.25c.621 0 1.125-.504 1.125-1.125v-1.5c0-.621-.504-1.125-1.125-1.125H3.375c-.621 0-1.125.504-1.125 1.125v1.5c0 .621.504 1.125 1.125 1.125z'
                    />
                  </svg>
                </button>
              </div>
            </Tooltip>
            <Tooltip
              title='Descargar Factura PDF'
              arrow
            >
              <div className='bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover rounded-lg h-12 shadow flex justify-center items-center'>
                <button
                  className='text-center p-4 text-white hover:text-white'
                  onClick={() => {
                    getInvoiceByFormat('pdf', data.row);
                  }}
                  disabled={!getChipColor(data.row.internalStatus)}
                >
                  <svg
                    width='30px'
                    height='30px'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                    stroke='#FFFFFF'
                  >
                    <g
                      id='SVGRepo_bgCarrier'
                      stroke-width='0'
                    ></g>
                    <g
                      id='SVGRepo_tracerCarrier'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    ></g>
                    <g id='SVGRepo_iconCarrier'>
                      {' '}
                      <path
                        d='M4 4C4 3.44772 4.44772 3 5 3H14H14.5858C14.851 3 15.1054 3.10536 15.2929 3.29289L19.7071 7.70711C19.8946 7.89464 20 8.149 20 8.41421V20C20 20.5523 19.5523 21 19 21H5C4.44772 21 4 20.5523 4 20V4Z'
                        stroke='#FFFFFF'
                        stroke-width='2'
                        stroke-linecap='round'
                      ></path>{' '}
                      <path
                        d='M20 8H15V3'
                        stroke='#FFFFFF'
                        stroke-width='2'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      ></path>{' '}
                      <path
                        d='M11.5 13H11V17H11.5C12.6046 17 13.5 16.1046 13.5 15C13.5 13.8954 12.6046 13 11.5 13Z'
                        stroke='#FFFFFF'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      ></path>{' '}
                      <path
                        d='M15.5 17V13L17.5 13'
                        stroke='#FFFFFF'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      ></path>{' '}
                      <path
                        d='M16 15H17'
                        stroke='#FFFFFF'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      ></path>{' '}
                      <path
                        d='M7 17L7 15.5M7 15.5L7 13L7.75 13C8.44036 13 9 13.5596 9 14.25V14.25C9 14.9404 8.44036 15.5 7.75 15.5H7Z'
                        stroke='#FFFFFF'
                        stroke-width='1.5'
                        stroke-linecap='round'
                        stroke-linejoin='round'
                      ></path>{' '}
                    </g>
                  </svg>
                </button>
              </div>
            </Tooltip>
            <Tooltip
              title='Descargar Factura XML'
              arrow
            >
              <div className='bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover rounded-lg h-12 shadow flex justify-center items-center'>
                <button
                  className='text-center p-4 text-white hover:text-white'
                  onClick={() => {
                    getInvoiceByFormat('xml', data.row);
                  }}
                  disabled={!getChipColor(data.row.internalStatus)}
                >
                  <svg
                    height='30px'
                    width='30px'
                    version='1.1'
                    id='_x32_'
                    viewBox='0 0 512 512'
                    fill='#FFFFFF'
                  >
                    <g
                      id='SVGRepo_bgCarrier'
                      stroke-width='0'
                    ></g>
                    <g
                      id='SVGRepo_tracerCarrier'
                      stroke-linecap='round'
                      stroke-linejoin='round'
                    ></g>
                    <g id='SVGRepo_iconCarrier'>
                      <g>
                        <path d='M378.406,0H208.29h-13.176l-9.314,9.314L57.013,138.102l-9.314,9.314v13.176v265.514 c0,47.36,38.528,85.895,85.895,85.895h244.812c47.368,0,85.895-38.535,85.895-85.895V85.896C464.301,38.528,425.773,0,378.406,0z M432.49,426.105c0,29.877-24.214,54.091-54.084,54.091H133.594c-29.877,0-54.091-24.214-54.091-54.091V160.591h83.717 c24.884,0,45.07-20.178,45.07-45.07V31.804h170.115c29.87,0,54.084,24.214,54.084,54.092V426.105z'></path>
                        <path d='M178.002,297.743l21.051-30.701c1.361-2.032,2.032-4.07,2.032-6.109c0-5.027-3.938-8.965-9.37-8.965 c-3.394,0-6.11,1.494-8.281,4.754l-16.575,24.452h-0.265l-16.576-24.452c-2.172-3.26-4.888-4.754-8.281-4.754 c-5.432,0-9.37,3.938-9.37,8.965c0,2.039,0.67,4.077,2.031,6.109l20.919,30.701l-22.546,33.138 c-1.355,2.039-2.039,4.077-2.039,6.116c0,5.027,3.938,8.965,9.371,8.965c3.393,0,6.116-1.494,8.288-4.755l18.203-26.896h0.265 l18.203,26.896c2.171,3.261,4.894,4.755,8.287,4.755c5.432,0,9.37-3.938,9.37-8.965c0-2.039-0.677-4.078-2.039-6.116 L178.002,297.743z'></path>
                        <path d='M291.016,251.968c-5.977,0-9.238,3.261-12.226,10.326l-19.284,44.547h-0.545l-19.697-44.547 c-3.121-7.066-6.382-10.326-12.358-10.326c-6.654,0-11.004,4.622-11.004,11.954v72.398c0,6.109,3.806,9.643,9.244,9.643 c5.153,0,8.958-3.534,8.958-9.643v-44.554h0.678l14.397,33.138c2.856,6.522,5.167,8.428,9.782,8.428 c4.615,0,6.927-1.906,9.782-8.428L283,291.766h0.684v44.554c0,6.109,3.666,9.643,9.098,9.643c5.432,0,9.098-3.534,9.098-9.643 v-72.398C301.88,256.59,297.67,251.968,291.016,251.968z'></path>
                        <path d='M373.211,327.355h-32.873c-0.544,0-0.824-0.272-0.824-0.816V262.56c0-6.381-4.203-10.592-9.915-10.592 c-5.837,0-10.04,4.21-10.04,10.592v72.532c0,5.976,3.938,10.054,10.04,10.054h43.611c6.102,0,10.04-3.666,10.04-8.965 C383.251,331.02,379.313,327.355,373.211,327.355z'></path>
                      </g>
                    </g>
                  </svg>
                </button>
              </div>
            </Tooltip>
          </div>
        ) : (
          <></>
        );
      },
    },
  ];

  return (
    <div className='w-full overflow-auto'>
      <div className='flex flex-row-reverse items-center'>
        <div className='flex items-center space-x-4'>
          <Tooltip title='Tu guía será generada y enviada al correo registrado'>
            <button
              type='button'
              onClick={() => setIsOpenModalReport(true)}
              className='inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-lg text-white bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover transition ease-in-out duration-150 mb-4'
            >
              Solicitar reporte
            </button>
          </Tooltip>
          {selectedRows.length > 0 && profile.canGenerateInvoices && (
            <button
              type='button'
              onClick={() => createInvoice({})}
              className='inline-flex items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-lg text-white bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover transition ease-in-out duration-150 mb-4'
            >
              {report.loading && (
                <svg
                  className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
                  xmlns='http://www.w3.org/2000/svg'
                  fill='none'
                  viewBox='0 0 24 24'
                >
                  <circle
                    className='opacity-25'
                    cx='12'
                    cy='12'
                    r='10'
                    stroke='currentColor'
                    stroke-width='4'
                  ></circle>
                  <path
                    className='opacity-75'
                    fill='currentColor'
                    d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
                  ></path>
                </svg>
              )}
              {report.loading ? 'Procesando...' : 'Generar Facturas'}
            </button>
          )}
        </div>

        <div className='flex flex-grow justify-start'>
          {userEmail && list && list.allEmails?.length ? (
            <SearchInputMultipleChoice
              mainAccount={userEmail || ''}
              placeholder='Selecciona los correos del grupo'
              options={list.allEmails || []}
              handleChange={function (value: string[]): void {
                handleEmailsSelection(value);
              }}
            />
          ) : (
            <></>
          )}
        </div>
      </div>

      {/* Invoice Modal */}
      <Modal
        onClose={() => setIsOpenModalInvoice(false)}
        isOpen={isOpenModalInvoice}
      >
        <Facturama />
      </Modal>
      {/* Support Payment Modal */}
      <Modal
        onClose={() => setIsOpenModalSupportPayment(false)}
        isOpen={isOpenModalSupportPayment}
      >
        <SupportPayment />
      </Modal>
      {/* report filter modal */}
      <Modal
        onClose={() => setIsOpenModalReport(false)}
        isOpen={isOpenModalReport}
        size='tiny'
      >
        <h1 className='text-xl'>Filtrar reporte</h1>
        <hr className='my-6'></hr>
        <h4 className='my-2'>Desde</h4>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            className='w-full'
            minDate={new Date('2021-01-01')}
            onChange={newValue => setReportFromDate(newValue)}
          />
        </LocalizationProvider>
        <h4 className='my-2'>Hasta</h4>
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            className='w-full'
            maxDate={new Date()}
            referenceDate={new Date()}
            onChange={newValue => setReportToDate(newValue)}
          />
        </LocalizationProvider>
        <hr className='my-4'></hr>
        <h2>Cambiar email por defecto</h2>
        <input
          className={`w-full border-2 py-4 px-4 mt-2 text-sm rounded-lg`}
          placeholder='Correos electrónicos separados por comas'
          type='text'
          onChange={e => setEmailsReport(e.target.value)}
        />
        <span className='text-gray-400 text-sm'>
          {' '}
          (user1@gmail.com, user2@gmail.com)
        </span>
        <hr className='my-4'></hr>
        <button
          type='button'
          onClick={() => requestReport()}
          className='inline-flex float-center items-center px-4 py-2 font-semibold leading-6 text-sm shadow rounded-lg text-white bg-primary-inabit-blue hover:bg-primary-inabit-blue-hover transition ease-in-out duration-150 mb-4'
        >
          {report.loading && (
            <svg
              className='animate-spin -ml-1 mr-3 h-5 w-5 text-white'
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
            >
              <circle
                className='opacity-25'
                cx='12'
                cy='12'
                r='10'
                stroke='currentColor'
                stroke-width='4'
              ></circle>
              <path
                className='opacity-75'
                fill='currentColor'
                d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
              ></path>
            </svg>
          )}
          {report.loading ? 'Procesando...' : 'Descargar reporte'}
        </button>
      </Modal>
      <DataGrid
        autoHeight
        localeText={{
          toolbarDensity: 'Tamaño de Columnas',
          toolbarDensityLabel: 'Size',
          toolbarDensityCompact: 'Pequeño',
          toolbarDensityStandard: 'Medio',
          toolbarDensityComfortable: 'Grande',
          toolbarExport: 'Exportar',
          toolbarFilters: 'Filtros',
          toolbarColumns: 'Columnas',
        }}
        rows={filteredData || []}
        columns={columns}
        pageSize={12}
        loading={controls.loading}
        className='rounded-xl'
        components={{ Toolbar: CustomToolbar }}
        density='comfortable'
        checkboxSelection={profile.canGenerateInvoices}
        isRowSelectable={params => {
          const isUserEmailMatch = params.row.userEmail === userEmail;
          return !params.row.transaction.invoiced && isUserEmailMatch;
        }}
        onSelectionModelChange={ids => {
          const selectedIDs = new Set(ids);
          const selectedRows = filteredData?.filter(row =>
            selectedIDs.has(row.id),
          );
          setSelectedRows(
            selectedRows?.map(e => ({
              transactionId: e.transaction._id,
              guide: e.trackingNumber,
              serviceName: e.serviceName,
            })) || [],
          );
        }}
      />
    </div>
  );
};

const withConnect = connect(
  selectState(
    'app.profile',
    'shipmentList.controls',
    'shipmentList.list',
    'shipmentList.report',
    'invoice.invoiceControls',
    'supportPayment.supportPaymentFields',
    'supportPayment.supportPaymentRes',
  ),
  actionProps({
    ...shipmentActions,
    ...invoiceActions,
    ...supportPaymentActions,
  }),
);

export default withRouter(withConnect(Shipments));
