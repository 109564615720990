import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { actionProps, selectState } from '../../../utils/reduxActions';
import { generateShipmentActions } from '../reducer';
import { bookAddressActions } from '../../Address/AddressBook/reducer';
import Swal, { SweetAlertResult } from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import paths from '../../../config/paths';
import { AddressDetail } from '../../../interfaces/general';
import { referenceProfiles } from './util';
import QuoteResume from '../../../commons/Components/QuoteResume';
const MySwal = withReactContent(Swal)

interface GenerateGuideForm {
  origin: AddressDetail
  destination: AddressDetail
  originSelected: string
  destinationSelected: string
  observations: string
  references: string
  saveOriginAddress: boolean;
  saveDestAddress: boolean;
}

interface AddressDetailProps {
  actions?: any
  shipmentControls?: any
  history?: any
  calculatorForm?: any
  productCatalog?: any
  list?: any
  profile?: any
}

const schema = yup.object({
  originSelected: yup.string(),
  destinationSelected: yup.string(),
  origin: yup.object({
    companyName: yup.string().max(35, 'Max. número de caracteres permitidos. 35').required('Campo requerido'),
    country: yup.string().required('Campo requerido').default('MEXICO'),
    state: yup.string().required('Campo requerido'),
    city: yup.string().required('Campo requerido'),
    colonyName: yup.string().required('Campo requerido'),
    postalCode: yup.string().required('Campo requerido'),
    streetName: yup.string().required('Campo requerido').max(35, 'La dirección debe contener  max. 35 caracteres'),
    streetNumber: yup.string().required('Campo requerido').max(10, 'Max. número de 10 cifras'),
    email: yup.string().matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g, 'Correo no valido').required('Campo requerido'),
    clientName: yup.string().max(100, 'Max. número de caracteres permitidos. 100').required('Campo requerido'),
    phoneNumber: yup.string()
      .min(10, 'Min. número de caracteres permitidos 10')
      .max(12, 'Max. número de caracteres permitidos 12')
      .required('Campo requerido'),
  }),
  destination: yup.object({
    companyName: yup.string().max(35, 'Max. número de caracteres permitidos. 35').required('Campo requerido'),
    country: yup.string().required('Campo requerido').default('MEXICO'),
    state: yup.string().required('Campo requerido'),
    city: yup.string().required('Campo requerido'),
    colonyName: yup.string().required('Campo requerido'),
    postalCode: yup.string().required('Campo requerido'),
    streetName: yup.string().required('Campo requerido').max(35, 'La dirección debe contener max. 35 caracteres'),
    streetNumber: yup.string().required('Campo requerido').max(10, 'Max. número de 10 cifras'),
    email: yup.string().matches(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/g, 'Correo no valido').required('Campo requerido'),
    clientName: yup.string().max(100, 'Max. número de caracteres permitidos. 100').required('Campo requerido'),
    phoneNumber: yup.string()
      .min(10, 'Min. número de caracteres permitidos 10')
      .max(12, 'Max. número de caracteres permitidos 12')
      .required('Campo requerido'),
  }),
  references: yup.string().max(40, 'Max. número de caracteres permitidos. 40'),
  observations: yup.string(),
  saveOriginAddress: yup.boolean().default(false),
  saveDestAddress: yup.boolean().default(false)
}).required();

const AddressDetailFormView: React.FC<AddressDetailProps> = (props) => {
  const { actions, shipmentControls, history, calculatorForm, list, profile } = props;
  const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm<GenerateGuideForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      destination: {
        postalCode: calculatorForm?.clientAddrDest?.zipCode
      },
      origin: {
        postalCode: calculatorForm?.clientAddrOrig?.zipCode
      }
    }
  });
  const originAddress = watch('originSelected')
  const destinationAddress = watch('destinationSelected')
  const origZipCodeQuoted = watch('origin.postalCode')
  const destZipCodeQuoted = watch('destination.postalCode')
  const [addressList, setAddressList] = useState([...list])

  const sortArray = (x: any, y: any) => {
    if (x.nameOfIndividual < y.nameOfIndividual) { return -1; }
    if (x.nameOfIndividual > y.nameOfIndividual) { return 1; }
    return 0;
  }

  useEffect(() => {
    const data = [...list]
    if (data.length > 0) setAddressList(data.sort(sortArray))
  }, [list])


  useEffect(() => {
    if (originAddress || calculatorForm.originSelected) {
      const originData = JSON.parse(originAddress || calculatorForm.originSelected)
      const data = {
        city: originData.address.city,
        colonyName: originData.address.colonyName,
        clientName: originData.nameOfIndividual,
        email: originData.email,
        phoneNumber: originData.phone,
        streetNumber: originData.address.interiorNumber,
        state: originData.address.state,
        stateCode: originData.stateCode,
        streetName: originData.address.address,
        postalCode: watch('origin.postalCode'),
        companyName: originData.companyName,
        country: 'MEXICO'
      }
      setValue('origin', data)
      actions.shipmentcontrolsChanged({ origin: data })
    }
  }, [actions, originAddress, setValue, watch, calculatorForm.originSelected])

  useEffect(() => {
    if (destinationAddress || calculatorForm.destinationSelected) {
      const destData = JSON.parse(destinationAddress || calculatorForm.destinationSelected)
      const data = {
        city: destData.address.city,
        colonyName: destData.address.colonyName,
        clientName: destData.nameOfIndividual,
        email: destData.email,
        phoneNumber: destData.phone,
        streetNumber: destData.address.interiorNumber,
        state: destData.address.state,
        stateCode: destData.stateCode,
        streetName: destData.address.address,
        postalCode: watch('destination.postalCode'),
        companyName: destData.companyName,
        country: 'MEXICO'
      }
      setValue('destination', data)
      actions.shipmentcontrolsChanged({ destination: data })
    }

    if (calculatorForm.clientAddrOrig?.zipCode !== origZipCodeQuoted ||
      calculatorForm.clientAddrDest?.zipCode !== destZipCodeQuoted
    ) {
      MySwal.fire({
        icon: 'warning',
        title: `Cambio de datos de cotización`,
        text: 'OJO!, estas cambiando los valores cotizados, si estos valores cambian deberas volver a cotizar antes de generar tu guia.',
        showCancelButton: true,
        confirmButtonText: 'Volver a cotizar'
      }).then((result: SweetAlertResult) => {
        if (result.isConfirmed) {
          history.push(paths.QUOTE)
        } else {
          setValue('origin.postalCode', calculatorForm.clientAddrOrig?.zipCode)
          setValue('destination.postalCode', calculatorForm.clientAddrDest?.zipCode)
        }
      })
    }
  }, [watch, destinationAddress, actions, setValue, calculatorForm, origZipCodeQuoted, destZipCodeQuoted, history, calculatorForm.destinationSelected]);

  if (Object.keys(calculatorForm?.shipmentSelect).length === 0) {
    MySwal.fire({
      icon: 'warning',
      title: `Generacion de envio invalida`,
      text: 'Para generar un envio primero debes cotizar y seleccionar el servicio deseado',
      confirmButtonText: 'Entendido'
    }).then((result: SweetAlertResult) => {
      history.push(paths.QUOTE)
    })
  }


  let destZipCodeData;
  let originZipCodeData;

  // generate list of zipCodeData to origin
  if (calculatorForm?.origData.length === 0) {
    originZipCodeData = { states: [], colonies: [], cities: [] }
  } else {
    let states: string[] = [];
    let colonies: string[] = [];
    let cities: string[] = [];
    calculatorForm?.origData.forEach((item: any) => {
      states.push(item.state)
      colonies.push(item.colonyName)
      cities.push(item.city)
    });
    states = [...new Set(states)]
    colonies = [...new Set(colonies)]
    cities = [...new Set(cities)]

    originZipCodeData = {
      states: states.map(item => ({ key: item, text: item, value: item })),
      colonies: colonies.map(item => ({ key: item, text: item, value: item })),
      cities: cities.map(item => ({ key: item, text: item, value: item }))
    }
  }

  // generate list of zipCodeData to destination
  if (calculatorForm?.destData.length === 0) {
    destZipCodeData = { states: [], colonies: [], cities: [] }
  } else {
    let states: string[] = [];
    let colonies: string[] = [];
    let cities: string[] = [];
    calculatorForm?.destData.forEach((item: any) => {
      states.push(item.state)
      colonies.push(item.colonyName)
      cities.push(item.city)
    });
    states = [...new Set(states)]
    colonies = [...new Set(colonies)]
    cities = [...new Set(cities)]
    destZipCodeData = {
      states: states.map(item => ({ key: item, text: item, value: item })),
      colonies: colonies.map(item => ({ key: item, text: item, value: item })),
      cities: cities.map(item => ({ key: item, text: item, value: item }))
    }
  }

  const addressBook = addressList.map((item: any) => ({
    key: item._id,
    value: JSON.stringify(item),
    text: `${item.nameOfIndividual}, ${item.address.colonyName}, ${item.address.state}, ${item.address.zipCode}`
  }))

  const nextStep = (data: GenerateGuideForm) => {
    actions.shipmentcontrolsChanged({ ...data, step: 2 })
    return true;
  }

  const disabledFieldRef = () => !profile?.referenceOnGuide || calculatorForm.ocurreService ? false : true
  const dataFromExternalShop = calculatorForm.shopifyOrder ?? calculatorForm.wooCommerceOrder;
  const clientName = `${dataFromExternalShop?.order?.shipping_address?.first_name} ${dataFromExternalShop?.order?.shipping_address?.last_name}`;

  return (
    <>
      <QuoteResume calculatorForm={calculatorForm} profile={profile} />
      <form onSubmit={handleSubmit(nextStep)}>
        <div className="grid grid-cols-2 space-x-4">
          {/* start section origin form  */}
          <div className="grid grid-cols-1 py-2 px-4 shadow-xl border-2 bg-white rounded-xl">
            <div className="my-4">
              <h3 className="font-bold text-2xl">Origen</h3>
            </div>
            <div>
              <select {...register('originSelected')}
                className={`w-full border-b-2 p-2 focus:outline-none ${errors.originSelected ? 'border-red-500' : ''}`}>
                <option key={""} value={""}></option>
                {addressBook.map((value: any) => (
                  <option key={value.key} value={value.value}>
                    {value.text}
                  </option>
                ))}
              </select>
            </div>
            <div className="grid grid-cols-2 space-x-4 my-2">
              <div className='grid'>
                <label className='mt-4 font-extrabold'>Nombre de la Persona</label>
                <input
                  className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.origin?.clientName ? 'border-red-500' : ''}`}
                  {...register('origin.clientName', { required: true })}
                  defaultValue={dataFromExternalShop?.shop?.name || shipmentControls.origin?.clientName}
                />
                <span className='text-red-600 font-semibold h-4'>{errors.origin?.clientName?.message}</span>
              </div>
              <div className='grid'>
                <label className='mt-4 font-extrabold'>Compañia</label>
                <input
                  className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.origin?.companyName ? 'border-red-500' : ''}`}
                  {...register('origin.companyName')}
                  defaultValue={dataFromExternalShop?.shop?.name || shipmentControls.origin?.companyName}
                />
                <span className='text-red-600 font-semibold h-4'>{errors.origin?.companyName?.message}</span>
              </div>
            </div>
            <div className="grid grid-cols-2 space-x-4 my-2">
              <div className='grid'>
                <label className='mt-4 font-extrabold'>Correo Electrónico</label>
                <input
                  className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.origin?.email ? 'border-red-500' : ''}`}
                  type="email"
                  {...register('origin.email', { required: true })}
                  defaultValue={dataFromExternalShop?.shop?.customer_email || shipmentControls.origin?.email}
                />
                <span className='text-red-600 font-semibold h-4'>{errors.origin?.email?.message}</span>
              </div>
              <div className='grid'>
                <label className='mt-4 font-extrabold'>Teléfono</label>
                <input
                  className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.origin?.phoneNumber ? 'border-red-500' : ''}`}
                  type="number"
                  {...register('origin.phoneNumber', { required: true })}
                  defaultValue={dataFromExternalShop?.shop?.phone || shipmentControls.origin?.phoneNumber}
                />
                <span className='text-red-600 font-semibold h-4'>{errors.origin?.phoneNumber?.message}</span>
              </div>
            </div>
            <h3 className="font-bold text-2xl mt-4">Dirección:</h3>
            <p className="font-bold text-lg"> Verifica los datos de dirección para evitar problemas con la recolección.</p>
            <div className="grid grid-cols-1 my-2">
              <label className='mt-4 font-extrabold'>Calle y Número</label>
              <input
                className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.origin?.streetName ? 'border-red-500' : ''}`}
                {...register('origin.streetName', { required: true })}
                defaultValue={dataFromExternalShop?.shop?.address1 || shipmentControls.origin?.streetName}
              />
              <span className='text-red-600 font-semibold h-4'>{errors.origin?.streetName?.message}</span>
            </div>
            <div className="grid grid-cols-2 space-x-4 my-2">
              <div className='grid'>
                <label className='mt-4 font-extrabold'>Número Interior</label>
                <input
                  className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.origin?.streetNumber ? 'border-red-500' : ''}`}
                  {...register('origin.streetNumber', { required: true })}
                  defaultValue={shipmentControls.origin?.streetNumber}
                />
                <span className='text-red-600 font-semibold h-4'>{errors.origin?.streetNumber?.message}</span>
              </div>
              <div className='grid'>
                <label className='mt-4 font-extrabold'>Código Postal</label>
                <input
                  className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.origin?.postalCode ? 'border-red-500' : ''}`}
                  {...register('origin.postalCode', { required: true })}
                  defaultValue={calculatorForm?.clientAddrOrig?.zipCode}
                />
                <span className='text-red-600 font-semibold h-4'></span>
              </div>
            </div>
            <div className="grid grid-cols-2 space-x-4 my-2">
              <div className="grid">
                <label className='mt-4 font-extrabold'>Colonia</label>
                <select {...register('origin.colonyName')}
                  className={`border-b-2 p-2 focus:outline-none ${errors.origin?.colonyName ? 'border-red-500' : ''}`}>
                  {originZipCodeData.colonies.map((value: any) => (
                    <option key={value.key} value={value.text} selected={shipmentControls.origin?.colonyName === value.text ? true : false}>
                      {value.text}
                    </option>
                  ))}
                </select>
              </div>
              <div className="grid">
                <label className='mt-4 font-extrabold'>Ciudad</label>
                <input
                  className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.origin?.city ? 'border-red-500' : ''}`}
                  {...register('origin.city', { required: true })}
                  defaultValue={shipmentControls.origin?.city || originZipCodeData.cities[0].text}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 space-x-4 my-2">
              <div className="grid">
                <label className='mt-4 font-extrabold'>Estado</label>
                <select {...register('origin.state')}
                  className={`w-full border-b-2 p-2 focus:outline-none ${errors.origin?.state ? 'border-red-500' : ''}`}>
                  {originZipCodeData.states.map((value: any) => (
                    <option key={value.key} value={value.text} selected={shipmentControls.origin?.state === value.text ? true : false}>
                      {value.text}
                    </option>
                  ))}
                </select>
              </div>
              <div className="grid">
                <label className='mt-4 font-extrabold'>País</label>
                <input
                  className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.origin?.country ? 'border-red-500' : ''}`}
                  {...register('origin.country', { required: true, disabled: true })}
                  defaultValue="MÉXICO"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 mt-8 mb-2">
              <div className='mb-1'>
                <input
                  className="h-6 w-6 mr-2 border border-gray-300 rounded-sm bg-white"
                  type="checkbox"
                  {...register("saveOriginAddress" as const)}
                />
                <label className="inline-block text-gray-800 text-center text-lg">
                  Guardar dirección de origen
                </label>
              </div>
            </div>
          </div>
          {/* end section origin form  */}
          {/* Start section destination form  */}
          <div className="grid grid-cols-1 py-2 px-4 shadow-xl border-2 bg-white rounded-xl">
            <div className="my-4">
              <h3 className="font-bold text-2xl">Destino</h3>
            </div>
            <div>
              <select {...register('destinationSelected')}
                className={`w-full border-b-2 p-2 focus:outline-none ${errors.destinationSelected ? 'border-red-500' : ''}`}>
                <option key={""} value={""}></option>
                {addressBook.map((value: any) => (
                  <option key={value.key} value={value.value}>
                    {value.text}
                  </option>
                ))}
              </select>
            </div>
            <div className="grid grid-cols-2 space-x-4 my-2">
              <div className='grid'>
                <label className='mt-4 font-extrabold'>Nombre de la Persona</label>
                <input
                  className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.destination?.clientName ? 'border-red-500' : ''}`}
                  {...register('destination.clientName', { required: true })}
                  defaultValue={clientName || shipmentControls.destination?.clientName}
                />
                <span className='text-red-600 font-semibold h-4'>{errors.destination?.clientName?.message}</span>
              </div>
              <div className='grid'>
                <label className='mt-4 font-extrabold'>Compañía</label>
                <input
                  className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.destination?.companyName ? 'border-red-500' : ''}`}
                  {...register('destination.companyName')}
                  defaultValue={dataFromExternalShop?.order?.shipping_address?.company || shipmentControls.destination?.companyName}
                />
                <span className='text-red-600 font-semibold h-4'>{errors.destination?.companyName?.message}</span>
              </div>
            </div>
            <div className="grid grid-cols-2 space-x-4 my-2">
              <div className='grid'>
                <label className='mt-4 font-extrabold'>Correo Electrónico</label>
                <input
                  className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.destination?.email ? 'border-red-500' : ''}`}
                  type="email"
                  {...register('destination.email', { required: true })}
                  defaultValue={dataFromExternalShop?.order?.contact_email || shipmentControls.destination?.email}
                />
                <span className='text-red-600 font-semibold h-4'>{errors.destination?.email?.message}</span>
              </div>
              <div className='grid'>
                <label className='mt-4 font-extrabold'>Teléfono</label>
                <input
                  className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.destination?.phoneNumber ? 'border-red-500' : ''}`}
                  type="number"
                  {...register('destination.phoneNumber', { required: true })}
                  defaultValue={dataFromExternalShop?.order?.phone || shipmentControls.destination?.phoneNumber}
                />
                <span className='text-red-600 font-semibold h-4'>{errors.destination?.phoneNumber?.message}</span>
              </div>
            </div>
            <h3 className="font-bold text-2xl mt-4">Dirección:</h3>
            <p className="font-bold text-lg"> Verifica los datos de dirección para evitar problemas con la recolección.</p>
            <div className="grid grid-cols-1 my-2">
              <label className='mt-4 font-extrabold'>Calle y Número</label>
              <input
                className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.destination?.streetName ? 'border-red-500' : ''}`}
                {...register('destination.streetName', { required: true })}
                defaultValue={dataFromExternalShop?.order?.shipping_address?.address1 || shipmentControls.destination?.streetName}
              />
              <span className='text-red-600 font-semibold h-4'>{errors.destination?.streetName?.message}</span>
            </div>
            <div className="grid grid-cols-2 space-x-4 my-2">
              <div className='grid'>
                <label className='mt-4 font-extrabold'>Número Interior</label>
                <input
                  className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.destination?.streetNumber ? 'border-red-500' : ''}`}
                  {...register('destination.streetNumber', { required: true })}
                  defaultValue={shipmentControls.destination?.streetNumber}
                />
                <span className='text-red-600 font-semibold h-4'>{errors.destination?.streetNumber?.message}</span>
              </div>
              <div className='grid'>
                <label className='mt-4 font-extrabold'>Código Postal</label>
                <input
                  className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.destination?.postalCode ? 'border-red-500' : ''}`}
                  {...register('destination.postalCode', { required: true })}
                  defaultValue={calculatorForm?.clientAddrDest?.zipCode}
                />
                <span className='text-red-600 font-semibold h-4'></span>
              </div>
            </div>
            <div className="grid grid-cols-2 space-x-4 my-2">
              <div className="grid">
                <label className='mt-4 font-extrabold'>Colonia</label>
                <select {...register('destination.colonyName')}
                  className={`border-b-2 p-2 focus:outline-none ${errors.destination?.colonyName ? 'border-red-500' : ''}`}>
                  {destZipCodeData.colonies.map((value: any) => (
                    <option key={value.key} value={value.text} selected={shipmentControls.destination?.colonyName === value.text ? true : false}>
                      {value.text}
                    </option>
                  ))}
                </select>
              </div>
              <div className="grid">
                <label className='mt-4 font-extrabold'>Ciudad</label>
                <input
                  className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.destination?.city ? 'border-red-500' : ''}`}
                  {...register('destination.city', { required: true })}
                  defaultValue={shipmentControls.destination?.city || destZipCodeData.cities[0].text}
                />
              </div>
            </div>
            <div className="grid grid-cols-2 space-x-4 my-2">
              <div className="grid">
                <label className='mt-4 font-extrabold'>Estado</label>
                <select {...register('destination.state')}
                  className={`w-full border-b-2 p-2 focus:outline-none ${errors.destination?.state ? 'border-red-500' : ''}`}>
                  {destZipCodeData.states.map((value: any) => (
                    <option key={value.key} value={value.text} selected={shipmentControls.destination?.state === value.text ? true : false}>
                      {value.text}
                    </option>
                  ))}
                </select>
              </div>
              <div className="grid">
                <label className='mt-4 font-extrabold'>País</label>
                <input
                  className={`py-1 border-b-2 text-gray-500 focus:outline-none ${errors.destination?.country ? 'border-red-500' : ''}`}
                  {...register('destination.country', { required: true, disabled: true })}
                  defaultValue="MÉXICO"
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 mt-8 mb-2">
              <div className='mb-1'>
                <input
                  className="h-6 w-6 mr-2 border border-gray-300 rounded-sm bg-white"
                  type="checkbox"
                  {...register("saveDestAddress" as const)}
                />
                <label className="inline-block text-gray-800 text-center text-lg">
                  Guardar dirección de destino
                </label>
              </div>
            </div>
          </div>
          {/* end section destination form  */}
        </div>
        <div className='flex flex-wrap p-4 shadow-xl border-2 mt-12 bg-white rounded-xl'>
          <span className='flex-none w-1/6 text-2xl font-bold'>Referencias: </span>
          <input
            readOnly={disabledFieldRef()}
            {...register('references', { required: true })}
            className={`w-5/6  py-1 border-b-2 focus:outline-none ${errors.references ? 'border-red-500' : ''}`}
            placeholder="Referencias para localizar lugar"
            defaultValue={profile?.referenceOnGuide || shipmentControls.origin?.rfc}
          />
          <span className='flex-none w-1/6 text-2xl font-bold'> </span>
          <span className='text-red-600 font-semibold h-4'>{errors.references?.message}</span>
        </div>
        {/* action buttons */}
        <div className="grid grid-cols-2 space-x-4 my-2">
          <div className='grid justify-items-start'>
            <button
              className='bg-primary-inabit-red text-white font-bold py-4 px-4 rounded-full w-64 mt-8 hover:bg-primary-inabit-coffe'
              onClick={() => {
                setValue('origin.colonyName', '')
                setValue('origin.email', '')
                actions.shipmentcontrolsChanged({ destination: {}, origin: {} })
                history.goBack()
              }}
            >
              Volver a cotizar
            </button>
          </div>
          <div className='grid justify-items-end'>
            <input
              type="submit"
              value="Siguiente"
              disabled={
                Object.keys(errors).length >= 1 ||
                calculatorForm.clientAddrOrig?.zipCode !== origZipCodeQuoted ||
                calculatorForm.clientAddrDest?.zipCode !== destZipCodeQuoted
              }
              className={`bg-primary-inabit-red text-white font-bold py-4 px-4 rounded-full w-64 mt-8 bg-primary-inabit-red text-white font-bold py-4 px-4 rounded-full w-64 mt-8 ${Object.keys(errors).length >= 1 ? 'opacity-50 cursor-not-allowed' : 'hover:bg-primary-inabit-coffe'}`}
            />
          </div>
        </div>
      </form>
    </>
  )
}

const withConnect = connect(
  selectState(
    'generateShipment.shipmentControls',
    'generateShipment.result',
    'calculator.calculatorForm',
    'addressBook.list',
    'app.profile',
  ),
  actionProps({ ...generateShipmentActions, ...bookAddressActions }),
);

export default withRouter(withConnect(AddressDetailFormView));
